import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { useState, useEffect } from "react";

import Home from "./components/Home";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Careers from "./components/Careers";
import Services from "./components/Services";
import Work from "./components/Work";
import About from "./components/About";
import Error from "./components/Error";

function App() {
  const [readyToShow, setReadyToShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReadyToShow(true);
    }, 4000);
  }, []);

  return (
    <div className="App">
      <Router>
        <div className={readyToShow ? "splash compress" : "splash"}>
          <img
            src={require("./files/favicon.png")}
            alt="Logo - Mathscript, computer programming - web, web-stranice, web-dizajn, web-shopovi i druge digitalne usluge"
          />
          <h1>Mathscript</h1>
          <h3>Postani dio digitalnog svijeta</h3>
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/politika-privatnosti" element={<PrivacyPolicy />} />
          <Route path="/karijere" element={<Careers />} />
          <Route path="/usluge" element={<Services />} />
          <Route path="/radovi" element={<Work />} />
          <Route path="/o-nama" element={<About />} />
          <Route path="*" element={<Error />} />
        </Routes>

        <CookieConsent
          location="bottom"
          buttonText="Prihvati kolačiće i krenimo dalje"
          cookieName="mathscript-tracker-ntc-cookie"
          style={{
            background: "rgba(0, 0, 0, 0.8)",
            zIndex: 2000000,
            height: "fit-content",
            display: "grid",
            placeContent: "center",
            placeItems: "center",
            boxShadow: "0 0 30px white",
            padding: "3rem 4rem",
            textAlign: "center",
            lineHeight: "1.4",
            fontSize: "clamp(var(--clamp-xs-text))",
            boxDecorationBreak: "clone",
            userSelect: "none",
          }}
          buttonStyle={{
            color: "white",
            backgroundColor: "#d82020",
            borderRadius: "20px",
            padding: "0.5rem 1rem",
            boxShadow: "0 0 10px white",
            fontWeight: "bold",
          }}
          expires={30}
        >
          Dobrodošao/la na stranice Mathscripta. Mi smo agencija za
          web-development koja nastoji oblikovati novu, digitalnu Zemlju. Prije
          nego počneš pregledavati našu stranicu i, nadamo se, započneš suradnju
          s nama, želimo te upoznati s kolačićima (engl. cookies). Vidio/la si
          ih barem tisuću puta dosad i već razumiješ da su to male podatkovne
          datoteke čija namjera nije špijunirati te, nego ti omogućiti bolje i
          ugodnije korisničko iskustvo. Stoga smo za tebe pripremili{" "}
          <Link
            to="/politika-privatnosti"
            style={{
              color: "white",
              backgroundColor: "#d82020",
              borderRadius: "20px",
              textDecoration: "none",
              paddingInline: ".3rem",
              boxDecorationBreak: "clone",
            }}
          >
            uvjete politike privatnosti
          </Link>{" "}
          na engleskom koje možeš pogledati. Slobodno nas pitaj ako imaš bilo
          kakve dodatne upite i nejasnoće, tu smo. Sada pritisni tipku sa strane
          i idemo na ozbiljniji posao.
        </CookieConsent>
      </Router>
    </div>
  );
}

export default App;
