import React from "react";
import "./AboutDetails.css";

import headquarters from "../files/headquarters.jpeg";

import react from "../files/reactjs.png";
import node from "../files/nodejs.jpeg";
import express from "../files/expressjs.jpeg";
import mongo from "../files/mongodb.png";
import reactnative from "../files/react-native.png";
import javascript from "../files/js.png";
import scss from "../files/sass.jpeg";
import css from "../files/html-css.png";
import figma from "../files/figma.png";
import ads from "../files/fb-ads.png";
import tensorflow from "../files/tf.png";

import CitiesAffialiate from "../components/CitiesAffiliate";
import ContactMap from "../components/ContactMap";

function AboutDetails() {
  return (
    <div className="about-details">
      <h1 className="link-controller blog-let-us-know form-h3">
        Naši ciljevi i kako to može pomoći tebi
      </h1>
      <ul className="goals">
        <li>
          Pomažemo našim suradnicima da svoje poslovanje usklade s vremenom
        </li>
        <li>
          Jednako pristupamo i malim i velikim organizacijama, ne pravimo
          razliku kako se često događa i prilagođavamo se posebnostima svakog
          posla
        </li>
        <li>
          Cijenu ne fiksiramo kroz čvrsto utvrđene pakete, već ju prilagođavamo
          proračunu i mogućnostima klijenta, kako bismo omogućili digitalne
          usluge svakome
        </li>
        <li>
          Uvijek nastojimo dogovoriti optimalan omjer cijene i kvalitete, pazeći
          da poslovanje ne gradimo na iskorištavanju klijenata, kako to danas
          često je slučaj, nažalost
        </li>
        <li>
          Dostupni smo klijentima svakodnevno, kako bismo osigurali da cijelo
          vrijeme imaju kvalitetnu uslugu
        </li>
        <li>
          Strogo se držimo dogovorenih i obećanih rokova izrade projekta, čak i
          više - gotovo uvijek izradu završimo i prije dogovorenog termina, kao
          znak posvećenosti klijentu
        </li>
        <li>
          Čak i novčano nagrađujemo ljude koji nam nisu klijenti ako nam nekoga
          preporuče kao suradnika
        </li>
        <li>
          Stalno prihvaćamo nove standarde, ne samo kriptovalutno plaćanje, već
          i mnogo toga drugog
        </li>
        <li>
          Svakom klijentu pišemo kod samo za njega, dakle - bez sporih
          predložaka koji izgledaju kao jeftine kopije i ograničenih su
          mogućnosti manipulacije. Klijent dobiva odličnu uslugu u tzv.
          MERN-tehnologiji koja projektima daje dojam kao da su mobilne
          aplikacije ugodne za korištenje
        </li>
        <li>... i puno više, ali shvatio si okvirno kako funkcioniramo...</li>
      </ul>
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      <h1 className="link-controller blog-let-us-know form-h3">
        Izbor nikada nije bio jednostavniji
      </h1>
      <div className="ad-last-why">
        <div className="ad-last-why-box">
          <h1>PIŠEMO CIJELI KOD, NE KORISTIMO NEPROFESIONALNE PREDLOŠKE</h1>
          <p>
            Mnoge agencije koriste i preprodaju jeftine i dosadne predloške
            kojima uništavaju reputaciju tvrtkama. Mnogi klijenti će prepoznati
            da je riječ o predlošku, a njih često opisuje sporost, dosadan
            dizajn i ostavljaju dojam da tvrtka koristi loša i jeftina rješenja
            u poslu. Popravak auta može ti odraditi i susjed koji se u to
            "pomalo razumije", ali dugoročne posljedice u pravilu su jako skupe.
          </p>
        </div>
        <div className="ad-last-why-box">
          <h1>MI TI JEDINI DAJEMO NOVAC AKO NAM PREPORUČIŠ PRIJATELJA</h1>
          <p>
            Ne trebaš nam čak biti niti korisnik. Ako znaš prijatelja, poznanika
            ili bilo koga drugog, a da bi trebao neku od usluga koje nudimo,
            javi nam. Ako se s njim dogovorimo, određeni postotak vrijednosti
            projekta ide tebi na račun. Nađeš li više korisnika, isto dobivaš za
            svakog posebno. Zato ne čekaj i javi nam odmah.
          </p>
        </div>
        <div className="ad-last-why-box">
          {" "}
          <h1>
            IMAŠ KRIPTO U NOVČANIKU I NE ZNAŠ NA ŠTO PAMETNO DA IH POTROŠIŠ?
          </h1>
          <p>
            U današnje vrijeme koje je nesigurno i nestabilno, ipak smo zadržali
            mogućnost plaćanja i kriptovalutama. Prihvaćamo ih najmanje 10
            (provjeri upitom). Postupak je iznimno brz, jednostavan i - ako ih
            imaš dovoljno za jednokratno plaćanje - tu smo, pitaj nas za sve
            detalje.
          </p>
        </div>
        <div className="ad-last-why-box">
          <h1>
            DOSTUPNI SMO SVAKI DAN I TVOJ PROJEKT IZRAĐUJEMO I PRIJE DOGOVORA
          </h1>
          <p>
            Sve ti je već jasno iz podnaslova. Kad god nas kontaktiraš, makar i
            vikendom, odgovorit ćemo. Naši klijenti su nam priznali da smo im
            projekte odradili u roku koji im nitko od konkurenata nije htio
            ponuditi. Nema puno mudrosti - volimo svoj posao i klijentima
            pristupamo odgovorno i s poštovanjem.
          </p>
        </div>
      </div>
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      <h1 className="link-controller blog-let-us-know form-h3">
        Evo što sve koristimo da bismo tvoj projekt učinili izvanrednim
      </h1>
      <div className="services-div">
        <div className="services-div-box">
          <img
            src={react}
            alt="React Javascript - Mathscript, agencija za web development i izradu web-stranica i rješenja"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">REACT JS</p>
            <p className="second-p">
              React je najkorišteniji i najrašireniji framework (točnije
              library) programskog jezika Javascript. Aplikacije u Reactu imaju
              dojam kao da se koristi mobilna aplikacija, a što ljudi danas
              obožavaju. Kod je podijeljen u komponente, a to čini kod ponovno
              iskoristivim i laganim za adaptaciju.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">NODE JS</p>
            <p className="second-p">
              Node koristimo za tzv. backend, sve ono što se odvija u pozadini
              aplikacije, poput povezivanja na baze podataka ili sve ostale
              funkcionalnosti koje nisu izravno vidljive kroz vanjsko primjetno
              sučelje koje vide korisnici pri korištenju.
            </p>
          </div>
          <img
            src={node}
            alt="Node Javascript - Mathscript, agencija za web development i izradu web-stranica i rješenja"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={express}
            alt="Express Javascript - Mathscript, agencija za web development i izradu web-stranica i rješenja"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">EXPRESS JS</p>
            <p className="second-p">
              Express je library koji služi tome da olakša korištenje Node JS-a,
              koji sam po sebi jest kompliciraniji i zahtijeva više vremena za
              pisanje koda. Express u konačnici pruža potpuno funkcionalnu
              aplikaciju kojom korisnici dobivaju ugodno korisničko iskustvo bez
              da razmišljaju uopće što se to u pozadini odvija i kako sve uredno
              radi.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">MONGO DB</p>
            <p className="second-p">
              Mongo je jedna od pet najkorištenijih baza podataka među
              programerima i developerima. Korištenje je intuitivno i savršeno
              se uklapa među ostale MERN-tehnologije (MERN - Mongo, Express,
              React, Node). Omogućuje nam da bitne podatke o tvojim korisnicima,
              poslovanju i slično spremimo kao stalno dostupne na internet, od 0
              do 24 sata.
            </p>
          </div>
          <img
            src={mongo}
            alt="Mongo Database kao jedna od tehnologija MERN (Mongo, Express, React, Node) - Mathscript, agencija za web development i izradu web-stranica i rješenja"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={javascript}
            alt="Javascript or JS — programski jezik, najkorišteniji za razvoj web-aplikacija i web-stranica - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">JAVASCRIPT</p>
            <p className="second-p">
              Ne trebamo puno pisati. JS je najkorišteniji, najrašireniji i
              najpoželjniji programski jezik današnjice. Njegova osnovna verzija
              poznata je kao Vanilla ili Plain JS, a u kombinaciji s ostalim
              tehnologijama iz MERN-a omogućuje izradu praktički bilo čega,
              poput frontenda, backenda ili sustava umjetne inteligencije (AI -
              artificial intelligence).
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">REACT NATIVE</p>
            <p className="second-p">
              React Native je svojevrsna verzija Reacta za mobilne aplikacije.
              Omogućuje izradu aplikacije koja se u pozadini konvertira i u
              Android i iOS, bez potrebe da se te dvije verzije izrađuju potpuno
              odvojeno i nepovezano, čime se više vremena poklanja kvaliteti
              projekta.
            </p>
          </div>
          <img
            src={reactnative}
            alt="React Native za razvoj mobilnih aplikacija, i za Android i iOS - Mathscript, agencija za web development i izradu web-stranica i rješenja"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={scss}
            alt="SCSS ili SASS, napredni ili SuperCSS, poznat i kao CSS sa supermoćima. Daje CSS-u dojam poput korištenja pravih programskih jezika kao što su npr. Javascript, Java, Python, PHP, C ili C++"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">SCSS (SASS)</p>
            <p className="second-p">
              SCSS ili SASS, napredni ili SuperCSS, poznat i kao CSS sa
              supermoćima. Daje CSS-u dojam poput korišenja pravih programskih
              jezika kao što su JS, Python, Java, PHP, C ili C++. U osnovi,
              krajnji rezultat je isti kao kod "običnog" CSS-a - oku ugodna i
              uredno stilizirana (dizajnirana) aplikacija.{" "}
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">CSS I HTML</p>
            <p className="second-p">
              Oni su osnova svakog web-rješenja. HTML stvara osnovni skeleton
              svake aplikacije, elemente poput linkova, teksta, slika, videa i
              slično, dok CSS daje stilove - boje, fontove, dizajn, pozicije u
              okviru stranice i ostalo.
            </p>
          </div>
          <img
            src={css}
            alt="HTML - Hypertext Markup Language i CSS - Cascade Stylesheet - Mathscript, agencija za web development i izradu web-stranica i rješenja"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={figma}
            alt="Figma, alat za dizajniranje za UX/UI design i frontend-development, slično kao i Adobe XD, Illustrator, Photoshop i neki slični malo dalji alati"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">FIGMA</p>
            <p className="second-p">
              Figma je alat koji koristimo za odrađivanje UX i UI-dizajna. UX
              predstavlja korisničko iskustvo pri korištenju, dok UI sami
              vizualni dojam. Krajnji cilj je dizajn ne samo ugodan oku
              promatrača, nego i poželjan za korištenje, koji ne tjera kupce od
              tvoje aplikacije, a što u konačnici donosi i profit tvom
              poslovanju.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">FACEBOOK I GOOGLE ADS</p>
            <p className="second-p">
              Da bi tvoj projekt ostvario svoj puni potencijal, nakon izrade
              treba ga predstaviti što široj publici - potencijalnim kupcima. To
              će ti u konačnici donijeti novac tvrtki, a onda i tebi. Mathscript
              stoga primjenjuje tzv. "pametno oglašavanje", tako da oglas
              maksimalno nastojimo preusmjeriti publici koja bi proizvod mogla
              željeti, bez bezveznog rasipanja novaca zbog često loše odrađenog
              marketinga.
            </p>
          </div>
          <img
            src={ads}
            alt="Facebookov logo za oglašavanje i marketing, bez Googleovog šarenog loga u bojama duge"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={tensorflow}
            alt="Tensorflow Javascript za umjetnu inteligenciju - artificial intelligence - projekte i sustave - Mathscript, agencija za web development i izradu web-stranica i rješenja, Zagreb, Hrvatska, Europska unija"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">TENSORFLOW JS</p>
            <p className="second-p">
              Tensorflow JS spaja Javascript i umjetnu inteligenciju, umjesto
              korištenja Pythona ili drugog uobičajenog jezika. S obzirom da u
              Mathscriptu po pitanju AI-ja pokrivamo određeni spektar usluga, za
              ovo područje nam se najprije obrati upitom, kako bismo provjerili
              možemo li ti pomoći u tvom slučaju.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      <h1 className="link-controller blog-let-us-know form-h3">
        Informacije o Mathscriptu na engleskom
      </h1>
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      {/*       More about...
       */}{" "}
      <div className="about-info-eng">
        <img
          src={require("../files/favicon.png")}
          alt="Plaćanje u kriptu poput bitcoina moguće je za web u Mathscriptu, IT-tvrtki iz Zagreba, Hrvatska i EU - prihvatljiv način plaćanja"
          draggable="false"
        />
        <a href="javascript:void(0)" className="first-link">
          <p>
            <span>Name: </span>Mathscript limited liability company for computer
            programming
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Short name: </span>Mathscript Ltd
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Headquarters: </span>Savska cesta 41, 10 000 Zagreb, the
            Republic of Croatia, the European Union
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Personal identification number: </span>47470088561
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Competent court (court register): </span>Trade Court in Zagreb
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Number in the register (MBS): </span>081403853
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>
              Share capital (according to Companies Law of the Republic of
              Croatia):{" "}
            </span>
            10 HRK
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Is share capital fully payed? </span>Yes
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Does the company operate in accordance with the laws? </span>
            Yes. Regular submission of annual financial reports to the tax
            administration.
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>
              CEO (<i>Croatian</i>: član uprave, direktor):{" "}
            </span>
            Tomislav Pilkić
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Bank: </span>Raiffeisenbank Austria d. d., Magazinska cesta
            69, 10 000 Zagreb, the Republic of Croatia, the European Union
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>SWIFT (for international payments): </span>RZBHHR2X
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>IBAN (International Bank Account Number): </span>
            HR5324840081135232784
          </p>
        </a>
      </div>
      {/*       ******************
       */}{" "}
      {/* ****************** */}
      {/* ****************** */}
      {/* ****************** */}
      {/* ****************** */}
      <h1 className="link-controller blog-let-us-know form-h3">
        Službene informacije na hrvatskom (Trgovački sud u Zagrebu)
      </h1>
      <div className="about-info-cro">
        <a href="javascript:void(0)" className="first-link">
          <p>
            <span>Naziv: </span>Mathscript jednostavno društvo s ograničenom
            odgovornošću za računalno programiranje
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Skraćeni naziv: </span>Mathscript j. d. o. o.
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Sjedište: </span>Savska cesta 41, 10 000 Zagreb
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>OIB: </span>47470088561
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Nadležni sud (sudski registar): </span>Trgovački sud u Zagrebu
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Broj up. u reg. (MBS): </span>081403853
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Iznos temeljnog kapitala: </span>10 HRK
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Temeljni kapital uplaćen u cijelosti: </span>da
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>Banka: </span>Raiffeisenbank Austria d. d., Magazinska cesta
            69, 10 000 Zagreb
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>SWIFT: </span>RZBHHR2X
          </p>
        </a>

        <a href="javascript:void(0)">
          <p>
            <span>IBAN: </span>HR5324840081135232784
          </p>
        </a>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="remove-padding">
        <CitiesAffialiate />
        <ContactMap />
        <img
          src={headquarters}
          alt="Sjedište i zgrada uprave Mathscripta j.d.o.o. ili Ltd, odnosno Mathscript j. d. o. o. u Zagrebu, Savska cesta 41"
          draggable="false"
          className="headquarters-img"
        />
      </div>
    </div>
  );
}

export default AboutDetails;
