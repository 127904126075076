import React from "react";
import "./GotProject.css";
import { Link } from "react-router-dom";

function GotProject() {
  function delayPreventDefault(e) {
    e.preventDefault();

    setTimeout(() => {
      window.location.href = "/kontakt";
    }, 1000);
  }

  return (
    <div className="got-project">
      <h1>Imaš projekt ili ideju?</h1>
      <p>
        Ako si donio odluku da svoj posao poguraš korak naprijed, slušamo. Imaš
        li bilo kakav upit, dvojbu, nejasnoću - nema glupih pitanja, samo glupih
        odgovora. Čekamo da nam se javiš.{" "}
      </p>
      <Link
        to="/kontakt"
        className="link-controller"
        onClick={delayPreventDefault}
      >
        ZAPOČNIMO SURADNJU
      </Link>
    </div>
  );
}

export default GotProject;
