import React from "react";
import "./ContactMap.css";

function ContactMap() {
  return (
    <div className="contact-map">
      <h3 className="link-controller blog-let-us-know form-h3">
        Glavno sjedište u središtu Zagreba
      </h3>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.6544387405543!2d15.95975701526247!3d45.79815031911929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d6ed43e1d8c9%3A0x653dc284f0612496!2sSavska%20cesta%2041%2C%2010000%2C%20Zagreb!5e0!3m2!1sen!2shr!4v1652890926279!5m2!1sen!2shr"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="MathscriptMap"
        className="google-math-map"
      ></iframe>
    </div>
  );
}

export default ContactMap;
