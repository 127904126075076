import React from "react";
import "./ListDescription.css";

import woodEurope from "../files/wood-europe.png";

import { RiStarSFill } from "react-icons/ri";

function ListDescription() {
  return (
    <div className="list-description">
      <h1>Još i više razloga zašto Mathscript</h1>
      <div className="list-description-box">
        <ul className="list-description-box-1">
          <li>
            <p>
              Ne koristimo predloške ili Wordpress, sami pišemo kod zza svakog
              korisnika posebno. Zato će tvoj projekt biti ugodan klijentu za
              korištenje.
            </p>
          </li>
          <li>
            <p>
              Projekti izrađeni u React JS-u čine se kao da su mobilne
              aplikacije zbog glatkoće korištenja. Korisnici danas to
              preferiraju.{" "}
            </p>
          </li>
          <li>
            <p>
              Imamo godine i godine iskustva u programiranju i radimo ovaj posao
              jer ga volimo, ne zato što moramo.{" "}
            </p>
          </li>
          <li>
            <p>Klijenti su nas ocijenili ocjenom od skoro 5.0. </p>
          </li>
          <li>
            <p>
              Nismo strogi pri određivanju cijena, već ju usklađujemo prema
              klijentu. Uvijek postignemo zajedničko rješenje.{" "}
            </p>
          </li>
          <li>
            <p>Možeš nas dobiti svaki dan, uključujući i vikende. </p>
          </li>
          <li>
            <p>
              Odgovor na pitanje nikad ne čekaš danima, već u najgorem slučaju
              satima. Neovisno o danu.{" "}
            </p>
          </li>
          <li>
            <p>
              Projekte izrađujemo značajno brže od konkurencije. U tome nam
              pomaže naše iskustvo.{" "}
            </p>
          </li>
          <li>
            <p>
              Ako imaš potrebu za time, možemo umjesto tebe pripremiti i sve
              tekstualne i slikovne materijale za aplikaciju.{" "}
            </p>
          </li>
          <li>
            <p>
              Ne bojimo se novih stvari, tako smo prihvatili BTC (bitcoin), ali
              i niz drugih kriptovaluta.
            </p>
          </li>
        </ul>
        <div className="list-description-box-2 ns-inner-text">
          <img
            src={woodEurope}
            alt="Teritorij Europske unije, karta prekrivena lišćem i jesenjim drvećem, simbolizirajući ekološki prihvatljivu stranu sektora IT"
            draggable="false"
          />
          <p>
            Naše su usluge ekološki prihvatljive i pomažu našem planetu da
            ostane lijep i čist za sve nas zajedno.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ListDescription;
