import React from "react";
import "./BlogHome.css";
import { Link } from "react-router-dom";

function BlogHome() {
  return (
    <div className="blog-home">
      <h1>Naš mali medijski kutak</h1>
      <p>
        S vremena na vrijeme, objavljujemo naše komentare i tekstove o pitanjima
        iz IT-sektora, našeg internog rada ili bilo čega povezanog s našom
        strukom. Također, imamo naše interne upute kako organizirano i
        ujednačeno pristupati svakom klijentu, kako bismo zadržali profesionalan
        pristup maksimalno.
      </p>
      <div className="blog-home-container">
        <div className="blog-home-container-box">
          <Link to="/blog">
            Zašto je tvoje poslovanje osuđeno kao poslovanje drugog reda bez
            prisutnosti na internetu?
          </Link>
        </div>
        <div className="blog-home-container-box">
          <Link to="/blog">Kako se okvirno određuje cijena projekta?</Link>
        </div>
        <div className="blog-home-container-box">
          <Link to="/blog">
            Razlozi zašto svoj projekt ne smiješ dati na izradu svakome.
          </Link>
        </div>
      </div>
      <div className="blog-home-line">
        * Zaposlenici imaju pristup glavnini sadržaja jer je vezan uz sam posao
        i pristup klijentima
      </div>
    </div>
  );
}

export default BlogHome;
