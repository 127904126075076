import React from "react";
import "./Services.css";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import ServicesDetails from "../components/ServicesDetails";

function Services() {
  document.title = "Usluge — Mathscript";

  return (
    <div className="services">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Pretvaranje ideje u stvarnost"
        middleHeader=""
        messageHeader="Možda se trenutno još ne poznajemo, ali prva stvar koju trebaš znati je da mi svoj posao doista volimo. Pogledaj kako to možemo prenijeti i na tvoje poslovanje da bi se istaknulo u odnosu na druge."
      />

      <ServicesDetails />

      <GotProject />
      <Footer />
    </div>
  );
}

export default Services;
