import React, { useEffect } from "react";
import "./ServicesDetails.css";
import { Link } from "react-router-dom";

import website from "../files/website-a.jpeg";
import webapp from "../files/webapp-a.jpeg";
import webshop from "../files/webshop-a.jpeg";
import design from "../files/design-a.jpeg";
import contentcr from "../files/content-create-a.jpeg";
import cms from "../files/cms-a.jpeg";
import seo from "../files/seo-a.jpeg";
import hosting from "../files/hosting-a.jpeg";
import socialmed from "../files/social-media-a.jpeg";
import sm from "../files/sm-a.jpeg";
import appdevelopment from "../files/mobile-app.jpeg";

import btc from "../files/bitcoin-logo.png";
import highFive from "../files/high-five.jpeg";
import volunteer from "../files/volunteer.jpeg";

function ServicesDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="sd">
      <h1 className="link-controller blog-let-us-know form-h3">
        Idemo vidjeti kako možemo pomoći tvojoj tvrtki ili organizaciji
      </h1>
      <div className="services-div">
        <div className="services-div-box">
          <img
            src={website}
            alt="Izrada internetskih stranica - Mathscript agencija za web i web-development"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">WEB-STRANICE</p>
            <p className="second-p">
              Svake godine na svijetu je 10-15 % više web-stranica. Sada ih je
              već oko dvije milijarde. Ne biti online danas u punom smislu znači
              steći lošu reputaciju. Znači biti primjetan i deset puta manje.
              Suvremeni poslovi takvo što ne smiju si danas dozvoliti. Promijeni
              to i neka tvoja organizacija počne s rastom.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">WEB-APLIKACIJE</p>
            <p className="second-p">
              Bez obzira vodiš li stan, putničku agenciju, restoran, kafić,
              neprofitnu organizaciju, stranku, banku, drugu financijsku
              instituciju, želiš samo proširiti prodaju diljem svijeta - neka
              tehnologija ubrza poslovanje kako bi se ti mogao posvetiti rastu
              prihoda. Ili ti je samo potreban osobni profesionalni blog ili
              nešto slično. Promijeni svoje poslovanje nabolje i brže sada.
            </p>
          </div>
          <img
            src={webapp}
            alt="Izrada web-aplikacija - Mathscript agencija za web i web-development"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={webshop}
            alt="Webshop - Mathscript web-development agency"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">WEBSHOP</p>
            <p className="second-p">
              Vremena trgovine "iz ruke u ruku" su iza nas. Veći dio današnje
              trgovine odvija se online i postotak svake godine drastično raste.
              Imajući internetsku trgovinu, nekoliko puta proširuješ opseg
              svojih kupaca i otvaraš nova vrata u digitalni svijet. Ne dopusti
              da te vrijeme zgazi i konkurencija da uzme više klijenata i novca
              koji su mogli pripasti tebi.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">UX/UI DIZAJN</p>
            <p className="second-p">
              Volimo boje, dizajn i da se stvari savršeno uklapaju. Također
              volimo pretvarati šarene ideje klijenata u stvarnost. Ljudi steknu
              dojam o tvom radu u prve tri sekunde posjeta. Potrudimo se onda
              ostaviti taj dojam kakav i treba biti. I kakav posao želi.
            </p>
          </div>
          <img
            src={design}
            alt="UX i UI dizajn, šareni uzorak - Mathscript agencija za web i web-development"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={contentcr}
            alt="Content creation umjesto korisnika, samo za web-stranice, aplikacije za web, webshop ili razvoj mobilnih aplikacija - Mathscript agencija za web i web-development"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">STVARANJE SADRŽAJA</p>
            <p className="second-p">
              Znamo da su vremena danas brza i da možda svatko neće imati
              vremena smisliti tekst ili potražiti slike za svoju stranicu. Ne
              brini, na tvoj zahtjev možemo to učiniti umjesto tebe. Iako
              najbolje poznaješ svoj posao sam, mi ćemo dati sve od sebe da te
              zamijenimo i izvučemo maksimum za tvrtku ili organizaciju.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">SAMOUREĐIVANJE U APLIKACIJI (CMS)</p>
            <p className="second-p">
              Trebaš često mijenjati sadržaj na stranici? Voliš li sve redovito
              mijenjati ili te tvoj posao jednostavno tjera na to, a stalno
              plaćanje manjih promjena na kraju postaje skupo? Nema problema,
              stranica može imati mogućnost uređivanja sadržaja koji ne ovisi o
              nama. Cijena može biti veća u početku, ali dugoročno imaš mir,
              fleksibilnost i neovisnost, a na kraju čak i uštedu novca kroz
              mjesece. Provjeri s nama.
            </p>
          </div>
          <img
            src={cms}
            alt="CMS - content management system, sustav za samouređivanje sadržaja - Mathscript agencija za web-development"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={seo}
            alt="SEO - search engine optimisation za Google rezultate pretrage i bolje pozicioniranje - Mathscript agencija za web-development"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">SEO-OPTIMIZACIJA</p>
            <p className="second-p">
              Do nekih je stranica lakše doći na Googleu, do nekih teže. To se
              može poboljšati izvođenjem tzv. SEO-a. Ne brini, mi u svaki
              projekt ugrađujemo najosnovniji SEO kako bi projekt bio
              prepoznatljiv, ali ako želiš, možemo napraviti "širu" i naprednu
              optimizaciju kako bi se stranica još više istaknula među prvim
              rezultatima.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">ODRŽAVANJE DOMENE I HOSTINGA</p>
            <p className="second-p">
              Nakon što službeno završimo projekt, stavljamo ga na internet. Da
              bi to bilo moguće, potrebno je imati zakupljenu i održavanu
              domenu, kao i prostor za pohranu stranice. Mathscript je tu da
              učini sve, a da ne mora korisnik razmišljati o tome što se događa
              u pozadini. Radi bolje svoj posao, a nama prepusti da radimo naš.
            </p>
          </div>
          <img
            src={hosting}
            alt="Mathscript web-agencija rješava hosting za korisnikovu web-stranicu i projekt, kao i kupnju domena, poput .com ili .eu ili .net ili .org ili neku drugu"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={socialmed}
            alt="Fotoaparat ispred natpisa za društvene mreže na engleskom jeziku - Mathscript, Zagreb, Stockholm i London"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">PAMETNO OGLAŠAVANJE I MARKETING</p>
            <p className="second-p">
              U redu, stranica je već online i posao izgleda ozbiljnije, ali
              želiš sada otići korak dalje i da ljudi čuju još više o poslu.
              Radimo pametan marketing, vodeći računa da oglas o organizaciji
              prikazujemo samo publici koja bi željela čuti o njoj, bez
              pretjeranog rasipanja novca jer je oglas loše postavljen. Ne
              brini, sve ćemo objasniti prije nego što doneses odluku.
            </p>
          </div>
        </div>

        <div className="services-div-box">
          <div className="inner-div2">
            <p className="first-p">ODRŽAVANJE POSLOVNIH DRUŠTVENIH MREŽA</p>
            <p className="second-p">
              Želiš li imati profile na nekoliko društvenih mreža kako bi svoj
              rad približio ciljanim ljudima, a nemaš vremena baviti se time? To
              je sasvim u redu - ovo je posao koji oduzima puno vremena i ne
              dopušta da se osoba posveti svom izvornom radu. Lako ćemo pristati
              da to preuzmemo na sebe prema tempu kako se dogovorimo.
            </p>
          </div>
          <img
            src={sm}
            alt="Stvaranje i uređivanje sadržaja na društvenim mrežama - Mathscript, agencija za web-development"
            draggable="false"
            className="services-img"
          />
        </div>

        <div className="services-div-box">
          <img
            src={appdevelopment}
            alt="Mobitel s otvorenom mobilnom aplikacijom na zaslonu skinutom iz iOS-trgovine Apple Store - Mathscript, agencija za web-development"
            draggable="false"
            className="services-img"
          />
          <div className="inner-div">
            <p className="first-p">MOBILNE APLIKACIJE (ANDROID I iOS)</p>
            <p className="second-p">
              Šećer je uvijek na kraju. Možda si čuo da se na Zapadu velika
              većina internetskog prometa odvija na mobilnim telefonima. Ako ne,
              razlog više je da se odlučiš ne samo za web-stranicu koja će
              izgledati savršeno na mobilnim telefonima, već i za aplikaciju. Ne
              treba govoriti, ali sam možeš pretpostaviti koliko je tvoja tvrtka
              bolja od konkurencije ako posjeduje takvo rješenje. Ne čekaj –
              istakni se.
            </p>
          </div>
        </div>
      </div>
      <h1 className="link-controller blog-let-us-know form-h3">
        Zaokružimo nabrzinu što nas čini drukčijima
      </h1>
      <p className="sd-different">
        Nije sramota biti drugačiji. Različiti su pisali povijest, pokretali
        napredak, stvarali bolji svijet. Zaokružimo još jednom stvari koje
        želimo da znaš i potičemo te da upravo sada pokažeš svoju jedinstvenost
        u odnosu na konkurenciju. Javi nam se.
      </p>
      <ul className="sd-list">
        <li>
          Rješavamo cijeli niz usluga iz područja IT-ja, ali i marketinga,
          dizajna i kreiranja sadržaja.
        </li>
        <li>
          Poštujemo potrebe klijenta i prilagođavamo mu se, razumijemo ga i
          uvijek nastojimo pronaći najbolju vrijednost za novac.
        </li>
        <li>
          Naš posao ne završava razvojem usluge, već i dalje vodimo računa da
          sve u pozadini radi savršeno, kako bi nam se klijent uvijek obraćao s
          povjerenjem.
        </li>
        <li>
          Klijentima smo dostupni svaki dan, uključujući i vikende. Potencijalne
          smetnje ne smiju smetati ničijim poslovima, bilo kojeg dana u godini.
        </li>
        <li>
          Klijenti su nas ocijenili ocjenom od gotovo 5.0. Ako te zanima zašto,
          budi slobodan to provjeriti i započnimo suradnju oko tvog projekta.
        </li>
        <li>
          Obično zahvaljujući našem iskustvu i ozbiljnosti završimo projekt puno
          prije od ostalih programera. Provjeri i iskoristi to.
        </li>
        <li>
          Da, točno je, prihvaćamo plaćanje u nizu kriptovaluta. Iskoristi ih na
          pametan način i uloži u svoj posao i sebe.
        </li>
        <li>
          Mathscript ti uplaćuje na račun ako nam dovedeš svog prijatelja kao
          klijenta. Određeni postotak vrijednosti projekta ide tebi izravno.
          Počni tražiti odmah.
        </li>
        <li>
          Ima još puno pogodnosti i posebnosti, ali više nećemo dosađivati. Još
          uvijek čekamo da nam predstaviš svoj projekt i konačno krenemo sa
          svime.
        </li>
      </ul>
      <h1 className="link-controller blog-let-us-know form-h3">
        Zanima te kako izrađujemo tvoj projekt?
      </h1>
      <h4>
        <Link to="/o-nama">
          Nema problema. Koristimo takozvanu MERN-tehnologiju. Možda to ništa ne
          znači ili zvuči kao neka nuklearna fizika. U tom slučaju, samo
          naprijed i preskočite to. Ali ako si ti taj koji želi znati detalje u
          pozadini, klikni i provjeri. Možeš nas dodatno pitati ako nismo bili
          dovoljno jasni. U redu je, svi smo samo ljudi i može se dogoditi da
          nismo na istoj valnoj duljini.
        </Link>
      </h4>

      <h1 className="link-controller blog-let-us-know form-h3">
        S našom uslugom ostvaruješ još i više
      </h1>

      <br />
      <br />
      <br />

      <p className="sd-benefits-info">
        1 PLAĆANJE KRIPTOM PRIHVATLJIVO <br />
        <span>
          Ako se odlučiš za plaćanje kriptovalutom, trebaš nam to najaviti prije
          nego što izdamo račun. Ako to učiniš, potrebno je platiti svu
          vrijednost računa u jednom obroku. Preračunat ćemo vrijednost u EUR u
          jednoj od odgovarajućih kriptovaluta prema tečaju na taj dan. Zatim
          ćemo poslati račun i nakon primitka informacija putem e-maila, trebat
          ćeš platiti u roku od 15 minuta. Nakon potvrde da je sve u redu,
          uglavnom isti dan, šaljemo poruku s najavom početka izrade projekta.
          Također, vratit ćemo novac ako nešto pođe u krivom smjeru, a greška je
          na strani Mathscripta. U svakom drugom slučaju nema mogućnosti povrata
          novca. Za podržane kriptovalute, molimo, kontaktiraj nas (jer može
          varirati s vremena na vrijeme).
        </span>{" "}
      </p>
      <p className="sd-benefits-info">
        2 PLAĆAMO TI AKO NAM PRONAĐEŠ KORISNIKA <br />
        <span>
          Stvar je vrlo jednostavna. Obratiš nam se porukom u kojoj nam
          preporučiš korisnika i navedeš svoje osobne podatke (točno ime,
          prezime, adresu i osobni identifikacijski broj). Ako preporučeni
          korisnik prihvati uslugu, ona je uspješno realizirana i uplaćen je
          cijeli iznos računa, određeni postotak te vrijednosti uplaćuje se na
          tvoj račun. Zatražit ćemo podatke o tvom računu na vrijeme. Postotak
          je 5 ili 8 %, ovisno o vrijednosti projekta (više ili manje od 1000
          EUR). Pogodnost možeš koristiti više puta, što znači da ćeš dobiti
          novac za svakog sljedećeg novog klijenta/osobu, koliko god da ih
          nađeš.
        </span>{" "}
      </p>
      <p className="sd-benefits-info">
        3 POPUST ZA NGO I MLAĐE TVRTKE <br />
        <span>
          Stvari su i ovdje poprilično jasne. Ako će račun biti za neprofitne
          organizacije, kao što su udruga, politička stranka, humanitarna
          organizacija itd., mi ćemo im naplatiti račun i tada dati popust od 10
          % na ukupnu vrijednost. Isto vrijedi i ako je tvoja profitabilna
          tvrtka formalno registrirana prije tri mjeseca ili manje. U svakom
          ovom slučaju treba imenovati na računu podatke o tom subjektu i
          odgovornoj osobi, tj. predsjedniku ili tajniku. Oni nam trebaju
          poslati pravni dokument kojim se potvrđuje status koji je razlog
          ostvarivanja popusta. Dokumenti za koje nije moguće provjeriti
          autentičnost bit će odbijeni kako bi se spriječile prijevare. Jedan
          subjekt ovu pogodnost može iskoristiti samo jednom - samo za prvi
          račun, bez obzira na vrijednost sljedećeg računa.
        </span>
      </p>
      <p className="sd-benefits-info">
        4 PLAĆANJE U RATAMA <br />
        <span>
          Dogovara se sa svakim pojedinim klijentom i ovisi od slučaja do
          slučaja. Prije svega o vrijednosti projekta, a zatim o duljini izrade,
          preferencijama i kreditnoj sposobnosti korisnika. Nije primjenjivo za
          plaćanja kriptovalutama.
        </span>
      </p>

      {/* <SomeBenefits /> */}

      <div className="some-benefits">
        <div className="some-benefits-box">
          <h1>PLAĆANJE KRIPTOM</h1>
          <h3>1 MIN</h3>
          <span>NAKON ŠTO ZAPRIMIŠ UPUTE</span>
          <img
            src={btc}
            alt="Bitcoin i druge kriptovalute prihvatljivo su sredstvo plaćanja u IT tvrtki (kompaniji) Mathscript, jedinoj koja to prihvaća u Zagrebu i Hrvatskoj za IT i web-usluge"
            draggable="false"
          />
          <p>
            Moguće je platiti u barem deset kriptovaluta. Potrebno je uplatiti
            cjelokupnu vrijednost odjednom i to unutar 15 min od zaprimanja
            uputa e-mailom prema dogovoru
          </p>
        </div>
        <div className="some-benefits-box">
          <h1>DOVEDI NAM SURADNIKA I DOBIVAŠ NOVCE</h1>
          <h3>8 %</h3>
          <span>OD VRIJEDNOSTI PROJEKTA PREBACUJEMO NA TVOJ RAČUN</span>
          <img
            src={highFive}
            alt="Mathscript daje određeni postotak vrijednosti projekta tebi na račun ako nam dovedeš klijenta"
            draggable="false"
          />
          <p>
            Ako nam preporučiš prijatelja, on uslugu prihvati i uspješno ju
            naplatimo, nakon svega ti u kratko roku na račun uplatimo 8 % od
            vrijednosti tog projekta, odnosno 5 % ako je vrijednost usluge iznad
            1000 EUR
          </p>
        </div>
        <div className="some-benefits-box">
          <h1>NGO ILI TVRTKA MLAĐA OD TRI MJESECA?</h1>
          <h3>— 10 %</h3>
          <span>SNIŽENJA UKUPNE CIJENE PROJEKTA</span>
          <img
            src={volunteer}
            alt="Nevladine organizacije (NGO), političke stranke, udruge i kompanije koje su službeno registrirane prije manje od tri mjeseca ostvaruju popust od 10 %"
            draggable="false"
          />
          <p>
            Neprofitne organizacije, političke stranke, udruge i tvrtke koje su
            službeno upisane u važeći registar prije manje od tri mjeseca
            odbivaju popust na ukupnu cijenu od 10 %
          </p>
        </div>
      </div>

      {/* <SomeBenefits /> */}

      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="coffee">
        <img
          src={require("../files/coffee.png")}
          alt="Čaša kave - početak svakog radnog dana u Mathscriptu"
          draggable="false"
        />
      </div>
      <h1 className="bright-text">
        Nekoliko čaša kave i tvoj projekt je spreman za internet.
      </h1>
    </div>
  );
}

export default ServicesDetails;
