import React from "react";
import "./CareersDetails.css";
import { Link } from "react-router-dom";

function CareersDetails() {
  return (
    <div className="careers-details">
      <h3 className="link-controller blog-let-us-know form-h3">
        Počnimo s onim što ti možemo ponuditi
      </h3>
      <div className="cd-offer">
        <div className="cd-offer-box">
          <img
            src={require("../files/flexwork.jpeg")}
            alt="Mathscript ti omogućuje rad na daljinu, čak i s laptopom na plaži i moru ako ti je tako draže."
          />
          <h3>FLEKSIBILNO RADNO VRIJEME I LOKACIJA</h3>
          <p>
            Formula je jednostavna — sve dok svoj dio posla radiš uredno i na
            vrijeme, potpuno nam je nebitno gdje, kako i kada radiš. Biraj sam.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/education.jpeg")}
            alt="Djevojka uči s knjigama u rukama - plaćene edukacije u Mathscriptu iz područja programiranja, kodiranja, izrade web-stranica i aplikacija i sličnog."
          />
          <h3>STALNO PLAĆENA EDUKACIJA</h3>
          <p>
            IT je područje u kojem je napredak stalan. Zato nam je jako bitno da
            su zaposlenici stalno u toku s najnovijim informacijama. Ne zbog
            nas, koliko zbog njih samih.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/conferenceslondon.jpeg")}
            alt="Mathscript organizira IT-konferencije, primjerice u Londonu, ali i u drugim dijelovima europskog kontinenta."
          />
          <h3>PUTOVANJA NA KONFERENCIJE</h3>
          <p>
            S vremena na vrijeme organiziramo putovanja na konferencije,
            pretežito na zapad i središnji dio Europe. Njihova je svrha
            potaknuti rad zaposlenika na sebi samima, svojim vještinama i -
            opuštanje. Konferencije mogu biti i viralne.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/plane.jpeg")}
            alt="Avion - zrakoplov uzlijeće s piste. Putovanja su najbolji lijek i odmor od stresa i posla."
          />
          <h3>NEPOSLOVNA PUTOVANJA</h3>
          <p>
            Također, Mathscript može ponekad organizirati putovanja koja imaju
            za cilj zabavu i opuštanje od rada, stresa i užurbanog života u
            gradu. Iako uglavnom u Europi, cilj je da u budućnosti to proširimo
            i na ostatak svijeta.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/feedback.jpeg")}
            alt="U Mathscriptu svaki zaposlenik dobije redoviti feedback kako bi poboljšao svoj rad."
          />
          <h3>DAJEMO TI POVRATNU INFORMACIJU</h3>
          <p>
            Radnike ne zanemarujemo, već im pristupamo s pažnjom i redovito im
            dajemo povratnu informaciju i druge prijedloge za poboljšanje, ako
            smatramo da bi mu/joj to moglo pomoći u poslu i životu općenito.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/bulb.jpeg")}
            alt="Žarulja kao simbol ideje i proaktivnosti."
          />
          <h3>POTIČEMO PROAKTIVNOST</h3>
          <p>
            Posebno cijenimo zaposlenike koji iznose nove korisne i pametne
            ideje kojima nam pomažu usmjeriti tvrtku nabolje. Svaki prijedlog
            uvijek uzimamo na razmatranje.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/football.jpeg")}
            alt="Djevojke igraju nogomet pred Svjetsko nogometno prvenstvo u Kataru, FIFA 2022. (Mathscript, Zagreb, Savska cesta 41)"
          />
          <h3>STOLNI NOGOMET I PUNO VIŠE</h3>
          <p>
            Svatko treba često predahnuti od posla. Iz tog razloga smo osmislili
            nekoliko načina za to. Stolni nogomet, odmor uz hranu u kuhinji,
            gledanje televizije, ali i druge zanimljive stvari...
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/daysoff.jpeg")}
            alt="Mathscript daje više slobodnih dana od propisanog zakonskog minimuma za godišnje odmore zaposlenika."
          />
          <h3>DODATNI DANI GODIŠNJEG ODMORA</h3>
          <p>
            Znamo točno što propisuje Zakon o radu, ali mi uvijek nastojimo
            pomaknuti standarde pa zaposlenicima omogućujemo više dana godišnjeg
            odmora od propisanog zakonskog minimuma.
          </p>
        </div>

        <div className="cd-offer-box">
          <img
            src={require("../files/roadout.jpeg")}
            alt="Cesta kao simbol promjene karijere - Mathscript j. d. o. o. / Mathscript j.d.o.o / Mathscript jdoo / Mathscript doo"
          />
          <h3>PROMJENA KARIJERE</h3>
          <p>
            Iako pri izradi projekata koristimo već spominjani tzv. MERN,
            prihvatili bismo i neke druge tehnologije koje su slične i mogle bi
            se uklopiti u opći koncept. Čak smo spremni i platiti dodatnu
            edukaciju za to.
          </p>
        </div>
      </div>
      <h3 className="link-controller blog-let-us-know form-h3">
        Osobine koje očekujemo od tebe
      </h3>
      <div className="cd-you">
        <img
          src={require("../files/redclouds.jpeg")}
          alt="Crveni oblaci - Mathscript (Zagreb), agencija za web-development"
          draggable="false"
        />

        <div className="cd-you-boxes">
          <div className="cd-you-box">
            <h3>UŽIVAŠ U SVOM POSLU</h3>
            <p>
              Apsolutno prvi uvjet. Ako ne voliš ono što radiš, a čeka te
              ostatak života u tom smjeru - dobro promisli o svojim planovima.
            </p>
          </div>
          <div className="cd-you-box">
            <h3>INTELIGENTNA OSOBA SPREMNA ZA UČENJE</h3>
            <p>
              Svaka osoba koja radi u IT-sektoru treba biti inteligentna, hrabra
              i uvijek pronalaziti ideje i rješenja. Vjerojatno si toga već i
              sam svjestan.
            </p>
          </div>
          <div className="cd-you-box">
            <h3>NE KLEČIŠ PRED PROBLEMIMA</h3>
            <p>
              U našem poslu s problemima se srećemo svaki dan. Ali isto tako
              znamo da svakom problemu treba odgovoriti protunapadom -
              rješenjem.
            </p>
          </div>
          <div className="cd-you-box">
            <h3>POZNAJEŠ SVOJ POSAO</h3>
            <p>
              Nitko od nas nije savršen, ali smo samostalni u svom radu, što
              ipak ne znači da i oni najiskusniji među nama nekad ne dignu dva
              prsta i traže pomoć. To je normalno.
            </p>
          </div>

          <div className="cd-you-box">
            <h3>PODRŽAVAŠ OSTALE U TIMU</h3>
            <p>
              Baš kao što ćeš nekada ti trebati pomoć, tako će trebati i tvoj
              kolega. Zato je dobro pomoći svakome jer se dobro dobrim vraća :).
            </p>
          </div>
        </div>
      </div>
      <h3 className="link-controller blog-let-us-know form-h3">
        Ispunjavaš li neke od ovih preduvjeta?
      </h3>
      <div className="cd-conditions">
        <div className="cd-conditions-box">
          <img
            src={require("../files/reactcareer.png")}
            alt="React JS programer (developer web-aplikacija i stranica) - Mathscript (Zagreb)"
          />
          <h3>REACT JS PROGRAMER</h3>
          <ul>
            <li>dvije godine iskustva</li>
            <li>odlično poznavanje JS-a</li>
            <li>poželjno korištenje funkcionalnih komponenata</li>
            <li>napredno korištenje tzv. hooksa</li>
            <li>barem nekoliko primjera osobnih radova</li>
            <li>obvezno napredno poznavanje CSS-a</li>
          </ul>
        </div>

        <div className="cd-conditions-box">
          <img
            src={require("../files/nodecareer.jpeg")}
            alt="Node JS programer (developer web-stranica) - Mathscript (Zagreb)"
          />
          <h3>NODE JS PROGRAMER</h3>
          <ul>
            <li>dvije godine iskustva</li>
            <li>odlično poznavanje Express JS-a</li>
            <li>izvanredno snalaženje u bazama podataka</li>
            <li>rad s Mongo DB-jem</li>
            <li>surađivanje s frontend-programerima</li>
            <li>napredno poznavanje JS-a</li>
          </ul>
        </div>

        <div className="cd-conditions-box">
          <img
            src={require("../files/figmacareer.png")}
            alt="Figma UX/UI dizajner - Mathscript (Zagreb)"
          />
          <h3>DIZAJNER (UX/UI)</h3>
          <ul>
            <li>dvije godine iskustva</li>
            <li>napredno poznavanje CSS-a i HTML-a</li>
            <li>korištenje nekog od alata poput Figme</li>
            <li>sposobnost stvaranja kompleksih radova i dizajna</li>
            <li>barem nekoliko primjera osobnih projekata</li>
            <li>komunikacija s klijentima na engleskom</li>
          </ul>
        </div>

        <div className="cd-conditions-box">
          <img
            src={require("../files/reactcareer.png")}
            alt="React Native programer ili developer mobilnih aplikacija za Android i iOS- Mathscript (Zagreb)"
          />
          <h3>REACT NATIVE PROGRAMER</h3>
          <ul>
            <li>dvije godine iskustva</li>
            <li>napredno poznavanje JS-a</li>
            <li>poznavanje funkcionalnih komponenata u Reactu</li>
            <li>poznavanje tzv. hooksa</li>
            <li>barem nekoliko primjera osobnih projekata</li>
            <li>prethodni rad povezan s Androidom ili iOS-om je prednost</li>
          </ul>
        </div>

        <div className="cd-conditions-box">
          <img
            src={require("../files/favicon.png")}
            alt="React JS programer - Mathscript (Zagreb)"
            className="logo-career"
            draggable="false"
          />
          <h3>NEKE DRUGE VJEŠTINE</h3>
          <p>
            AKo smatraš da imaš neke druge vještine koje bi nam mogle biti
            korisne i zanimljive, ne čekaj, javi se odmah. pošalji nam svoje
            radove, životopis, opis svog znanja i odgovorit ćemo ti sigurno,
            bilo pozitivno ili da smo te registrirali ako se stvori novo radno
            mjesto. Naravno, ako ćeš ti imati želju tada nam odgovoriti.
          </p>
        </div>
      </div>

      <h3 className="link-controller blog-let-us-know form-h3">
        Trenutno otvorene pozicije
      </h3>
      <h4>
        Trenutno nema otvorenih pozicija. Kada će ih biti, bit će navedene ispod
        u nastavku s detaljiziranim opisima. Usporedno će oglasi biti i na
        društvenim mrežama s ciljem da se lakše ciljaju osobe koje bi takav
        oglas mogao zanimati.
      </h4>

      <h3 className="link-controller blog-let-us-know form-h3">
        Misliš i dalje da imaš vještine za nas?{" "}
      </h3>
      <p className="open-job-text">
        Možeš se otvoreno prijaviti za posao u bilo kojem trenutku ako misliš da
        imaš vještine da se uklopiš u sve što si pročitao iznad. Slobodno klikni
        ispod i ispuni standardni obrazac za kontakt, a mi obećavamo da ćemo
        odgovoriti čak i ako nam trenutno ne treba novo radno mjesto. Ako želiš,
        uvijek ćemo te moći kasnije nazvati.
      </p>
      <Link className="open-job-link" to="/kontakt">
        ISPUNI KONTAKTNI OBRAZAC
      </Link>
    </div>
  );
}

export default CareersDetails;
