import React, { useEffect } from "react";
import "./Error.css";
import { Link } from "react-router-dom";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";

function Error() {
  document.title = "404 — Ovdje nema ničega (Mathscript)";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  function delayPreventDefault(e) {
    e.preventDefault();

    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  return (
    <div className="error">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Nothing found here"
        middleHeader="Error 404"
        messageHeader=""
      />

      <div className="error-handler">
        <h3>
          Bok. Čini se da je poteškoća u unesenom URL-u kojeg si utipkao u
          tražilici. Vrijednost <span>{window.location.href}</span> ne postoji.
          Provjeri jesi li slučajno nešto krivo utipkao ili još bolje — koristi
          naš orijentir u gornjem desnom kutku stranice za lakšu orijentaciju.
        </h3>
        <Link to="/" className="link-controller" onClick={delayPreventDefault}>
          NAZAD NA POČETNU
        </Link>
      </div>

      <GotProject />
      <h1 className="bright-text">
        Imaš poteškoće? Javi nam da ti odmah pomognemo.
      </h1>

      <Footer />
    </div>
  );
}

export default Error;
