import React, { useEffect } from "react";
import "./Work.css";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import WorkDetails from "./WorkDetails";

function Work() {
  document.title = "Radovi — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="work">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Od prvih koraka do rasta"
        middleHeader=""
        messageHeader="S mnogima smo prošli njihovu fazu od početka do rasta i bili dio tog procesa. Razumijemo da su i oni veliki sudionici na tržištu nekada bili mali pa zato jednako vrednujemo svaku osobu. Nastavljamo pomagati i dalje."
      />

      <WorkDetails />

      <GotProject />
      <Footer />
    </div>
  );
}

export default Work;
