import React from "react";
import "./IntroductionBox.css";
import { Link } from "react-router-dom";

function IntroductionBox(props) {
  return (
    <div className="introduction-box">
      <h1>{props.introductionLabel}</h1>
      <h3>{props.introductionExplanation}</h3>
      <Link to={props.introductionLink}>Pogledaj više informacija</Link>
      {props.children}
    </div>
  );
}

export default IntroductionBox;
