import React from "react";
import "./Footer.css";

import { MdDashboard } from "react-icons/md";
import { MdViewQuilt } from "react-icons/md";
import { RiBubbleChartFill } from "react-icons/ri";

import { MdMarkEmailRead } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { BsFacebook } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { GrLinkedin } from "react-icons/gr";
import { BsTwitter } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { FaViber } from "react-icons/fa";

import btc from "../files/bitcoin-logo.png";
import rainy from "../files/rainy.jpeg";
import eu from "../files/eu.jpeg";
import mathscript from "../files/favicon.png";
import { Link } from "react-router-dom";

function Footer() {
  const date = new Date();
  const day = date.getDate().toString();
  const year = date.getFullYear().toString();
  const month = date.getMonth() + 1;

  var monthName;

  switch (month) {
    case 1:
      monthName = "siječnja";
      break;
    case 2:
      monthName = "veljače";
      break;
    case 3:
      monthName = "ožujka";
      break;
    case 4:
      monthName = "travnja";
      break;
    case 5:
      monthName = "svibnja";
      break;
    case 6:
      monthName = "lipnja";
      break;
    case 7:
      monthName = "srpnja";
      break;
    case 8:
      monthName = "kolovoza";
      break;
    case 9:
      monthName = "rujna";
      break;
    case 10:
      monthName = "listopada";
      break;
    case 11:
      monthName = "studenog";
      break;
    case 12:
      monthName = "prosinca";
      break;
    default:
      monthName = "NEPOZNAT MJESEC";
  }

  return (
    <div className="set100">
      <div className="footer-main">
        <div className="footer-rect rect-one">
          <Link to="/">Početna</Link>
          <Link to="/o-nama">O nama</Link>
          <Link to="/usluge">Usluge</Link>
          <Link to="/radovi">Radovi</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/karijere">Karijere</Link>
          <Link to="/kontakt">Kontakt</Link>
          <Link to="/politika-privatnosti">Politika privatnosti</Link>
          <Link to="/error">Error - 404</Link>

          <p className="btc-text">
            Kripto prihvatljiv kao sredstvo plaćanja (ne samo bitcoin, i mnogi
            ostali){" "}
          </p>

          <img
            src={btc}
            alt="Plaćanje kriptom moguće u Mathscriptu, jedinoj IT-tvrtci u Zagreb, Hrvatska i Europska unija, a koja to prihvaća kao platežno sredstvo uz eure i kune do zamjene valuta u Hrvatskoj 2023."
            className="btc-img"
            draggable="false"
          />

          <p className="btc-text">
            Ako nam nekog preporučiš kao klijenta, određen postotak vrijednosti
            projekta uplaćujemo ti na račun
          </p>

          <img
            src={rainy}
            alt="Ako nam nekog preporučiš kao klijenta, određen postotak vrijednosti
            projekta uplaćujemo ti na račun - Mathscript u Zagrebu, IT-tvrtka za web-development, web-aplikacije, web-stranice i web-rješenja"
            className="btc-img"
            draggable="false"
          />
        </div>

        <div className="footer-rect rect-two">
          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Naziv: </span>Mathscript jednostavno društvo s ograničenom
              odgovornošću za računalno programiranje
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Skraćeni naziv: </span>Mathscript j. d. o. o.
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Sjedište: </span>Savska cesta 41, 10 000 Zagreb
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>OIB: </span>47470088561
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Nadležni sud (sudski registar): </span>Trgovački sud u
              Zagrebu
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Broj up. u reg. (MBS): </span>081403853
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Iznos temeljnog kapitala: </span>10 HRK
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Temeljni kapital uplaćen u cijelosti: </span>da
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>Banka: </span>Raiffeisenbank Austria d. d., Magazinska cesta
              69, 10 000 Zagreb
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>SWIFT: </span>RZBHHR2X
            </p>
          </a>

          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "white",
              cursor: "inherit",
            }}
          >
            <p>
              <span>IBAN: </span>HR5324840081135232784
            </p>
          </a>

          <img
            src={mathscript}
            alt="Logo - Mathscript (crvena i crna boja u krugu, slično središnjem dijelu zastave Južne Koreje)"
            className="btc-img logo-footer"
            draggable="false"
          />
          <p className="logo-footer-text">
            Mathscript, trgovačko društvo za računalno programiranje sa
            sjedištem u Zagrebu, Republika Hrvatska, Europska unija (EU)
          </p>
        </div>

        <div className="footer-rect rect-three">
          <a
            href="mailto:mathscript@mathscript.hr"
            target="_blank"
            rel="noreferrer"
          >
            <p>mathscript@mathscript.eu</p> <MdMarkEmailRead />
          </a>
          <a
            href="mailto:support@mathscript.hr"
            target="_blank"
            rel="noreferrer"
          >
            <p>support@mathscript.eu</p> <HiOutlineMail />
          </a>
          <a
            href="https://www.facebook.com/MathscriptEU/"
            target="_blank"
            rel="noreferrer"
          >
            <p>Facebook</p> <BsFacebook />
          </a>
          <a
            href="https://www.instagram.com/mathscript.eu/?hl=en"
            target="_blank"
            rel="noopener"
          >
            <p>Instagram</p> <GrInstagram />
          </a>
          <a
            href="https://www.linkedin.com/in/mathscript-web-development-agency-106930232/"
            target="_blank"
            rel="noreferrer"
          >
            <p>LinkedIn</p> <GrLinkedin />
          </a>
          <a
            href="https://twitter.com/MathscriptEU"
            target="_blank"
            rel="noreferrer"
          >
            <p>Twitter</p> <BsTwitter />
          </a>
          <a
            href="viber://chat?number=385977656906"
            target="_blank"
            rel="noreferrer"
          >
            <p>Viber (poruka)</p> <FaViber />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=385977656906"
            target="_blank"
            rel="noreferrer"
          >
            <p>Whatsapp (poruka)</p> <BsWhatsapp />
          </a>

          <img
            src={eu}
            alt="Zastava Europske unije - zastava EU. Mathscript financiran sredstvima iz fondova EU."
            draggable="false"
          />
          <h3>Financirano sredstvima iz fondova EU (web izradio Mathscript)</h3>
        </div>
      </div>

      <div className="cr">
        <p>Mathscript - sva prava pridržana</p>
        <div>
          <MdDashboard className="icon-red" />
          <MdViewQuilt className="icon-red" />{" "}
          <RiBubbleChartFill className="icon-red" />{" "}
        </div>
        <p>{day + ". " + " " + monthName + " " + year + ". "}</p>
      </div>
    </div>
  );
}

export default Footer;
