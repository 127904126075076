import React from "react";
import "./WorkDetails.css";
import LastProjects from "../components/LastProjects";
import ProjectExamples from "../components/ProjectExamples";

function WorkDetails() {
  return (
    <div className="wd">
      <h1 className="link-controller blog-let-us-know form-h3">
        Zašto je danas važno biti digitaliziran?
      </h1>
      <div className="wd-crucial-over">
        <div className="wd-crucial">
          <h1>Skoro 80 %</h1>
          <h3>ljudi kupuje nakon pretraživanja na internetu</h3>
          <hr className="wd-line" />
          <p>
            Okvirno, to znači da već u početku gubiš značajan dio potencijalnih
            klijenata i novca s njima, a i da će osobe steći lošije mišljenje o
            tvom poslu ako nije prisutno i na internetu. Ukupno, riječ je o
            ogromnim novčanim gubitcima na duže razdoblje.{" "}
          </p>
        </div>

        <div className="wd-crucial">
          <h1>Više od 60 %</h1>
          <h3>
            neće ni razmatrati kupnju od tvrtke koja nije prisutna na webu
          </h3>
          <hr className="wd-line" />
          <p>
            Ljudi danas ne gledaju svijet isto kao prije deset godina. Poslovi
            koji se tome ne prilagode će propasti. Istraživanja čak pokazuju i
            da većina neće kupiti proizvod i od dobro poznate tvrtke ako nema
            web-stranicu. Za manje tvrtke podatci su i porazniji.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>Oko 60 do 70 %</h1>
          <h3>malih tvrtki posjeduje web-stranice</h3>
          <hr className="wd-line" />
          <p>
            Dakle, ako si napravio pomak i odmaknuo sebe i posao iz negativnog
            dijela statistike, već si u boljem položaju od trećine svojih
            konkurenata. Ako se opireš promjenama, čeka te situacija gdje će
            ljudi odlaziti od tebe tražeći ih malo dalje. Ne dopusti da ti se
            poslovanje marginalizira bespotrebno.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>Barem 50 %</h1>
          <h3>subjekata bez weba razmatra opciju da to riješi</h3>
          <hr className="wd-line" />
          <p>
            Čak i ona manjina koja nije iskoristila pogodnosti digitalizacije
            svjesna je koje to teške i opasne posljedicee može nositi sa sobom i
            stoga i oni razmatraju da to hitno razriješe. Ne brini, s nama ćeš
            se lako dogovoriti jer se mi prilagođavamo tvojim mogućnostima. Javi
            nam se.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>Oko jedne trećine</h1>
          <h3>direktora svoje poslovanje smatra premalim da bi imali web</h3>
          <hr className="wd-line" />
          <p>
            Time već u početku rade katastrofalnu pogrešku. Jednom stečen loš
            glas teško je naknadno otkloniti. Mathscript poštuje to da nisu sve
            tvrtke u početku snažne i stoga pakete ponude prilagođava raznim
            prilikama, tako i malim "igračima". Provjeri s nama, naći ćemo neko
            zajedničko rješenje.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>Tri sekunde</h1>
          <h3>
            je razdoblje u kojem korisnik donosi odluku ostati i kupiti ili se
            povući
          </h3>
          <hr className="wd-line" />
          <p>
            Za svaku sekundu neučitavanja stranice, prodajna konverzija opada 10
            %. Gore je i to da će preko 60 % korisnika napustiti tvoju stranicu
            ako se ne učita u tri sekunde i gotovo ssvi se više neće htjeti
            vratiti. Zato je bitno da je stranica moderna i povremeno održavana
            da bi bila prilagođena klijentima i vremenu.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>Više od 90 %</h1>
          <h3>dat će ti bolju ocjenu samo ako imaš web</h3>
          <hr className="wd-line" />
          <p>
            Samo ako posjkeduješ web ljudi će tvoje poslovanje smatrati
            ozbiljnijim i vrednovati ga bolje. To je zato što svi mi danas sve
            prvo tražimo na webu. Sami znamo kako ćemo lošije gledati na posao
            kojeg ne možemo pronaći na internetu. Razlog je osjećaj tajnovitosti
            i skrivanja kojeg trebate odmaknuti od sebe.
          </p>
        </div>

        <div className="wd-crucial">
          <h1>Barem 95 %</h1>
          <h3>
            na Zapadu kupovat će sve na internetu kroz sljedećih 20 godina
          </h3>
          <hr className="wd-line" />
          <p>
            Promjene su naša stvarnost i ignoriranje ih neće odmaknuti. Doista,
            Mathscript je jedna od rijetkih tvrtki koja je to prihvatila i
            prilagodila se čak i manjim korisnicima, često zanemarivanima. Javi
            nam se da bismo ti to mogli i pokazati na tvom projektu.
          </p>
        </div>
      </div>
      <h1 className="link-controller blog-let-us-know form-h3">
        Još malo dodatne statistike — čitaj pažljivo
      </h1>
      <p className="wd-stats">
        Malo ljudi zna da obično dodavanje bloga na stranicu stvari mijenja
        radikalno. Stranice koje redovito održavaju blog (jedna objava svakam
        dva dana ili slično) imaju{" "}
        <span>više od 95 % više poveznica na sebe</span>. Iako se u početcima
        sve čini skupo i financijski teško izdržljivo, na duge se staze isplati
        i rezultirat će povratkom novca kroz kraće vrijeme, a ne gubitkom.
      </p>
      <p className="wd-stats">
        Regularno održavanje bloga opisano maloprije također pozitivno utječe na
        posao. Procjene kažu da tada u prosjeku{" "}
        <span>takvi poslovi dobivaju tri puta više posla, nego bez bloga</span>.
        Stoga, iako se štednjom štedi novac u samom početku, poslovi bez
        profesionalnog bloga imaju veće mogućnosti negativnog ishoda. Pametne
        odluke zato treba donositi odmah.
      </p>
      <p className="wd-stats">
        Značajan broj stranica loše je kvalitete. Na primjer, kontakti nisu
        prisutni na početnoj stranici, a to spriječi javljanje značajnog broja
        posjetitelja. Još više, značajan broj stranica nije prilagođen za
        mobitele i tablete. Ako uzmemo u obzir da glavnina prometa na internetu
        danas dolazi upravo preko mobitela, sve to značajno umanjuje kvalitetu
        poslovanja.{" "}
        <span>
          Postavi stoga stranicu koju profesionalci održavaju 24 sata dnevno bez
          iznimke
        </span>
        .
      </p>
      <p className="wd-stats">
        Mnogi ljudi ne žele imati web jer se boje negativnih kritika, osobito
        preko lažnih profila i konkurencije. Kako god, procjenjuje se da je ipak
        preko 90 % komentara pozitivno, što se lako vidi i pregledavanjem osvrta
        na pojedine tvrtke, posebno one manje.{" "}
        <span>Hrabrost i je jedan od glavnih osobina poduzetnika</span>.
        Uostalom, veće opterećenje klijentima je upravo ono što bi svatko i htio
        u svom poslu, zar ne?
      </p>
      <p className="wd-stats">
        Preko 70 % ljudi preferira{" "}
        <span>
          kupnju online jer vjeruju da je lakša, jednostavnija, praktičnija i da
          je lakše utjecati na konačni iznos cijene
        </span>{" "}
        nego u fizičkim poslovnicama. Baš zato korisnici telekomunikacijskih
        usluga više vole zvati svoje korisničke službe.
      </p>
      <p className="wd-stats">
        Ljepota ljudi je u raznolikosti. Veliki broj njih su sramežljivi i
        neugodno im je izravno kupovati. Zamisli samo koliko je potencijalnih
        klijenata{" "}
        <span>
          htjelo nešto kupiti od tebe, ali su odustali jer nisu to mogli
          riješiti preko interneta ili anonimno
        </span>
        . Nemamo za to neku statistiku, ali shvatio si. Biti prisutan online
        znači daleko veću bazu potencijalnih korisnika.
      </p>
      <p className="wd-stats">
        <span>2 000 000 000</span>. Ovaj ogromni broj je trenutni broj
        web-stranica u svijetu okvirno i on raste svake sekunde doslovno. Isti
        podatci za mobilne aplikacije još su veći. Danas doslovno ne postoji
        ikakav prihvatljiv izgovor za nanošenje štete ugledu svog posla i za
        odbijanje pogodnosti novog doba.
      </p>
      <h1 className="link-controller blog-let-us-know form-h3">
        Zašto nas klijenti prepoznaju kao pouzdane suradnike?
      </h1>
      <div className="ns-inner change-back">
        <div className="ns-inner-text">
          <h1>20+</h1>
          <h3>PROJEKATA</h3>
          <span></span>
          <p>Samo kao Mathscript, isključujući ostala iskustva</p>
        </div>
        <div className="ns-inner-text">
          <h1>5+</h1>
          <h3>GODINA ISKUSTVA</h3>
          <span></span>
          <p>
            Višegodišnje iskustvo i educiranost u programiranju i kodiranju i
            stalni rad na proširenju znanja.
          </p>
        </div>
        <div className="ns-inner-text">
          <h1>4.8</h1>
          <h3>PROSJEČNA OCJENA NAŠIH KORISNIKA</h3>
          <span></span>
          <p>
            Ovo je ocjena kojom su nas u prosjeku ocijenili naši klijenti koji
            su htjeli poslati povratnu informaciju.
          </p>
        </div>
        <div className="ns-inner-text">
          <h1>120+</h1>
          <h3>SATI GODIŠNJE EDUKACIJE</h3>
          <span></span>
          <p>
            Broj sati plaćene edukacije po radniku tijekom razdoblja od jedne
            godine.
          </p>
        </div>
      </div>
      <p className="wd-stats">
        Kada završimo svaki projekt, volimo čuti mišljenje klijenta o našem
        radu. Nastojimo skupiti sve kritike i ocjene i{" "}
        <span>popraviti to za sljedeći projekt</span>. Tako rastemo kao moderna
        agencija i postajemo sve bolji iz projekta u projekt. Zahvalni smo na
        svakoj rečenici koja nam daje savjet jer nas to čini ozbiljnijima svakim
        danom.
      </p>

      <ProjectExamples />
      <LastProjects />

      <h4>
        U Mathscriptu ne namećemo, nego surađujemo s klijentima. Oni to
        prepoznaju i ocjenjuju nas natprosječno. To je jedan od razloga da
        uvijek nađemo zajednički jezik. Pristupamo i malima i velikima s
        jednakim poštovanjem i želimo po tome biti prepoznati. Krenimo sve to
        primjenjivati na tvom projektu.
      </h4>
    </div>
  );
}

export default WorkDetails;
