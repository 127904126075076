import React, { useState } from "react";
import "./MathscriptForm.css";
import { Link } from "react-router-dom";
import axios from "axios";

import { BsFillStarFill } from "react-icons/bs";

function MathscriptForm() {
  const [senderName, setSenderName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [selectService, setSelectService] = useState("");
  const [budget, setBudget] = useState("");
  const [selectHeard, setSelectHeard] = useState("");
  const [describeProject, setDescribeProject] = useState("");

  const [warnMessage, setWarnMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorText, setErrorText] = useState("");

  function submitMathscriptForm(e) {
    e.preventDefault();

    const message = `<h3>Name of the sender: </h3> ${senderName} </hr><br/>
    <h3>Name of the company: </h3> ${companyName} </hr><br/>
    <h3>E-mail address: </h3> ${senderEmail} </hr><br/>
    <h3>Phone number of the sender: </h3> ${senderPhone} </hr><br/>
    <h3>Selected service (if nothing written, it means WEBSITE): </h3> ${selectService} </hr><br/>
    <h3>Budget: </h3> ${budget} </hr><br/>
    <h3>Where did user hear for Mathscript (if nothing written, other person recommendation is chosen): </h3> ${selectHeard} </hr><br/>
    <h3>Message: </h3> ${describeProject} </hr><br/>
    `;

    axios
      .post("https://server.mathscript.hr/send", {
        message: message,
      })
      .then(() => {
        setWarnMessage(true);
      })
      .catch((e) => {
        setErrorMessage(true);
        setErrorText(e.message);
      });

    setSenderName("");
    setCompanyName("");
    setSenderEmail("");
    setSenderPhone("");
    setSelectService("");
    setBudget("");
    setSelectHeard("");
    setDescribeProject("");

    e.target.reset();
  }

  console.log(senderName);
  console.log(senderPhone);
  console.log(senderEmail);
  console.log(selectService);
  console.log(describeProject);

  return (
    <form className="mathscript-form" onSubmit={submitMathscriptForm}>
      <h3 className="link-controller blog-let-us-know form-h3">
        Napiši nam nešto o sebi
      </h3>
      <label htmlFor="senderName">Tvoje ime</label>
      <input
        type="text"
        name="senderName"
        id="senderName"
        onChange={(e) => setSenderName(e.target.value)}
      />

      <label htmlFor="companyName">Naziv tvrtke ili organizacije</label>
      <input
        type="text"
        name="companyName"
        id="companyName"
        onChange={(e) => setCompanyName(e.target.value)}
      />

      <label htmlFor="senderEmail">
        E-mail <span>obvezno</span>
      </label>
      <input
        type="email"
        name="senderEmail"
        id="senderEmail"
        required
        onChange={(e) => setSenderEmail(e.target.value)}
      />

      <label htmlFor="senderPhone">Broj mobitela</label>
      <input
        type="phone"
        name="senderPhone"
        id="senderPhone"
        onChange={(e) => setSenderPhone(e.target.value)}
      />

      <h3 className="link-controller blog-let-us-know form-h3">
        Objasni nam svoj projekt
      </h3>

      <label htmlFor="selectService">
        Kako ti možemo pomoći? <span>obvezno</span>
      </label>

      <select
        name="selectService"
        id="selectService"
        required
        onChange={(e) => setSelectService(e.target.value)}
      >
        <option value="Website or web-application">
          Web-stranica ili web-aplikacija
        </option>
        <option value="Mobile application">Aplikacija za mobitele</option>
        <option value="Design (UX/UI)">Dizajn (UX/UI)</option>
        <option value="Marketing or advertising">
          Marketing ili oglašavanje
        </option>
        <option value="Other or more services together or different subject">
          Druga pitanja ili više usluga zajedno
        </option>
      </select>

      <label htmlFor="selectHeard">
        Reci nam kako si točno čuo za nas. <span>obvezno</span>
      </label>

      <select
        name="selectHeard"
        id="selectHeard"
        required
        onChange={(e) => setSelectHeard(e.target.value)}
      >
        <option
          value="Other person's recommendation"
          onChange={(e) => setSelectHeard(e.target.value)}
        >
          Preporuka druge osobe
        </option>
        <option value="Facebook">Facebook</option>
        <option value="Google Ads">Google Ads</option>
        <option value="Other social networks">Druge društvene mreže</option>
        <option value="While searching in Google search bar">
          Slučajnim pronalaskom u tražilici Googlea
        </option>
        <option value="Something not mentioned here (tell us in a message)">
          Nekako drukčije (možeš napisati u poruci)
        </option>
      </select>

      <label htmlFor="budget">Tvoj proračun za projekt (nije obvezno)</label>
      <input
        type="text"
        name="budget"
        id="budget"
        onChange={(e) => setBudget(e.target.value)}
      />

      <label htmlFor="describeProject">
        Opiši nam svoje pitanje detaljnije i ponovi e-mail za svaki slučaj{" "}
        <span>obvezno</span>
      </label>
      <textarea
        name="describeProject"
        id="describeProject"
        required
        onChange={(e) => setDescribeProject(e.target.value)}
      ></textarea>

      <h3 className="link-controller blog-let-us-know form-h3">
        Zadnje upute prije slanja
      </h3>

      <p className="disclaimer">
        Pritiskom na tipku za slanje slažeš se s{" "}
        <Link to="/politika-privatnosti">
          politikom privatnosti Mathscripta
        </Link>{" "}
        i sadržajem i pravilima koja tamo pišu. S tvojim podatcima danima ovdje
        postupat će se u skladu sa zakonima, pretežito GDPR-om (General Data
        Protection Regulation). To znači da je korištenje podataka strogo
        isključeno za bilo koju drugu svrha koja nije vezana uz sam odgovor na
        upit.
      </p>
      <input type="checkbox" name="gdprOK" id="gdprOK" checked={true} />
      <p className="disclaimer">Odgovor obično stiže u svega nekoliko sati.</p>
      <p className="disclaimer">
        Ako naiđeš na bilo kakve poteškoće prilikom slanja obrascem, budi
        slobodan obratiti nam se nekim od drugih komunikacijskih kanala
        navedenih ispod.{" "}
      </p>
      <p className="rate-us">
        <BsFillStarFill />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us-right" />
        Na kraju svog odgovora, ne zaboravi nam dopisati i jesi li zadovoljan
        ukupnim korisničkim iskustvom na ovoj stranici i našim pristupom. Ako
        nisi, molimo te da nam opišeš što bismo trebali promijeniti kako bismo
        uslugu učinili još boljom u budućnosti. Hvala puno na iskrenosti.
        {"     "}
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
        <BsFillStarFill className="star-rate-us" />
      </p>
      <button type="submit" value="POŠALJI PORUKU" className="submit-button">
        POŠALJI PORUKU
      </button>

      {warnMessage && (
        <h3 style={{ color: "green" }}>
          <br />
          <br />
          <br />
          Poruka je uspješno poslana. Molimo te da pričekaš kratko i šaljemo ti
          odgovor. Ako ništa ne zaprimiš unutar 12 sati od slanja, slobodno nam
          pošalji podsjetnik izravno e-mailom (za slučaj da se nije dogodilo
          nešto nepredviđeno).
        </h3>
      )}
      {errorMessage && (
        <h3 style={{ color: "#d82020" }}>
          <br />
          <br />
          <br />
          {errorText}
        </h3>
      )}
    </form>
  );
}

export default MathscriptForm;
