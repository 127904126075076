import React, { useEffect } from "react";
import "./Contact.css";

import Navigation from "../components/Navigation";
import CanvasDraw from "../components/CanvasDraw";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import MathscriptForm from "../components/MathscriptForm";
import ContactMoreData from "../components/ContactMoreData";
import ContactMap from "../components/ContactMap";

import headquarters from "../files/headquarters.jpeg";

function Contact() {
  document.title = "Kontaktirajte nas — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="contact">
      <Navigation />
      <SpreadMenu />
      <CanvasDraw
        header="Želiš nas pitati?"
        middleHeader="Tu smo da saslušamo tvoj upit."
        messageHeader="Slobodno nas upoznaj sa svojom idejom, projektom ili dvojbom. Dobit ćeš odgovor brže nego što si mislio."
      />

      <MathscriptForm />
      <ContactMoreData />
      <ContactMap />

      <img
        src={headquarters}
        alt="Sjedište Mathscripta j. d. o. o. (jdoo, j.d.o.o., d.o.o., doo, d. o. o., Ltd) u Zagrebu, Savska cesta 41"
        draggable="false"
        className="headquarters-img"
      />

      <h1 className="bright-text">
        Hvala ti na posjetu. Na raspolaganju smo ti i dalje.
      </h1>

      <Footer />
    </div>
  );
}

export default Contact;
