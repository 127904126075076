import React from "react";
import "./SomeBenefits.css";
import btc from "../files/bitcoin-logo.png";
import highFive from "../files/high-five.jpeg";
import volunteer from "../files/volunteer.jpeg";

function SomeBenefits() {
  return (
    <div className="some-benefits-over">
      Neke zanimljive pogodnosti
      <div className="some-benefits">
        <div className="some-benefits-box">
          <h1>PLAĆANJE KRIPTOM</h1>
          <h3>1 MIN</h3>
          <span>NAKON ŠTO ZAPRIMIŠ UPUTE</span>
          <img
            src={btc}
            alt="Bitcoin i druge kriptovalute prihvatljivo su sredstvo plaćanja u IT tvrtki (kompaniji) Mathscript, jedinoj koja to prihvaća u Zagrebu i Hrvatskoj za IT i web-usluge"
            draggable="false"
          />
          <p>
            Moguće je platiti u barem deset kriptovaluta. Potrebno je uplatiti
            cjelokupnu vrijednost odjednom i to unutar 15 min od zaprimanja
            uputa e-mailom prema dogovoru
          </p>
        </div>
        <div className="some-benefits-box">
          <h1>DOVEDI NAM SURADNIKA I DOBIVAŠ NOVCE</h1>
          <h3>8 %</h3>
          <span>OD VRIJEDNOSTI PROJEKTA PREBACUJEMO NA TVOJ RAČUN</span>
          <img
            src={highFive}
            alt="Mathscript daje određeni postotak vrijednosti projekta tebi na račun ako nam dovedeš klijenta"
            draggable="false"
          />
          <p>
            Ako nam preporučiš prijatelja, on uslugu prihvati i uspješno ju
            naplatimo, nakon svega ti u kratko roku na račun uplatimo 8 % od
            vrijednosti tog projekta, odnosno 5 % ako je vrijednost usluge iznad
            1000 EUR
          </p>
        </div>
        <div className="some-benefits-box">
          <h1>NGO ILI TVRTKA MLAĐA OD TRI MJESECA?</h1>
          <h3>— 10 %</h3>
          <span>SNIŽENJA UKUPNE CIJENE PROJEKTA</span>
          <img
            src={volunteer}
            alt="Nevladine organizacije (NGO), političke stranke, udruge i kompanije koje su službeno registrirane prije manje od tri mjeseca ostvaruju popust od 10 %"
            draggable="false"
          />
          <p>
            Neprofitne organizacije, političke stranke, udruge i tvrtke koje su
            službeno upisane u važeći registar prije manje od tri mjeseca
            odbivaju popust na ukupnu cijenu od 10 %
          </p>
        </div>
      </div>
    </div>
  );
}

export default SomeBenefits;
