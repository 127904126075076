import React, { useEffect, useRef } from "react";
import "./Blog.css";
import { Link } from "react-router-dom";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import SpreadMenu from "../components/SpreadMenu";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";

import { ImWarning } from "react-icons/im";

function Blog() {
  document.title = "Blog — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const modalRef = useRef();

  function openModalWarn() {
    modalRef.current.classList.add("show-modal");
    setTimeout(() => {
      modalRef.current.classList.remove("show-modal");
    }, 9000);
  }

  return (
    <div className="blog">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Naš medijski kutak"
        middleHeader="Blog, pomoćni i drugi interni materijali"
        messageHeader="Dobrodošao. Molimo te da verificiraš svoj račun odlaskom na poseban link i korištenjem pristupnih podataka koji su ti poslani posebnim e-mailom, kako bi ostvario pravo pristupa materijalima iz tvrtke."
      />

      <div className="blog-home-container">
        <div className="blog-home-container-box">
          <Link to="/blog" onClick={openModalWarn}>
            Zašto je tvoje poslovanje osuđeno kao poslovanje drugog reda bez
            prisutnosti na internetu?
          </Link>
        </div>
        <div className="blog-home-container-box">
          <Link to="/blog" onClick={openModalWarn}>
            Kako se okvirno određuje cijena projekta?
          </Link>
        </div>
        <div className="blog-home-container-box">
          <Link to="/blog" onClick={openModalWarn}>
            Razlozi zašto svoj projekt ne smiješ dati na izradu svakome.
          </Link>
        </div>
      </div>

      <div className="modal-warn" ref={modalRef}>
        <img
          src={require("../files/mathscript.jpg")}
          alt="Mathscript j. d. o. o., j.d.o.o., jdoo, doo, Ltd - logo (Zagreb, Hrvatska, web-agencija)"
        />{" "}
        <br />
        <br />
        Trenutno nije otvoreno javno. Otvorit ćemo pristup osnovnim člancima već
        kroz nekoliko tjedana. <br />
        <br />
        Prati nas na društvenim mrežama ili nas pitaj ako trebaš informacije{" "}
        <Link
          to="/kontakt"
          style={{
            color: "#d82020",
            backgroundColor: "white",
            borderRadius: "10px",
            paddingInline: "0.5rem",
            textDecoration: "none",
            letterSpacing: "4px",
          }}
        >
          klikom ovdje
        </Link>{" "}
        na poveznicu.{" "}
      </div>

      <div className="blog-restricted">
        <p>
          Više sadržaja neće biti vidljivo bez odobrenog pristupa i validacije
          iz Mathscripta.
        </p>
      </div>

      <ImWarning className="warn-icon-blog" />

      <p className="blog-let-us-know">
        Da bi pristupio internom sadržaju, Trebaš imati poseban link za pristup
        s pristupnim podatcima. Ako ih imaš, a nešto ipak ne radi,{" "}
        <a href="mailto:mathscript@mathscript.hr">slobodno nas pitaj </a> da
        bismo bez odgađanja riješili situaciju.
        <br />
        <br />
        <br />
        <br />
        <br />
        <i>
          <strong style={{ color: "#4eada2" }}>
            {" "}
            Plan je otvoriti veći dio bloga za javnu upotrebu kroz mjesec dana,
            nakon razdvajanja sadržaja od poslovnog internog materijala.
            Obavijestit ćemo sve na vrijeme kako bi mogli pratiti zanimljiv
            sadržaj redovito.{" "}
          </strong>{" "}
        </i>
      </p>
      <GotProject />
      <Footer />
    </div>
  );
}

export default Blog;
