import React from "react";
import "./Introduction.css";
import IntroductionBox from "./IntroductionBox";
import { whatWeDo, frameworks } from "../files/Details.js";

import { BsPatchQuestionFill } from "react-icons/bs";
import { FaReceipt } from "react-icons/fa";
import { BsFillPenFill } from "react-icons/bs";
import { MdAppRegistration } from "react-icons/md";
import { FaPaintBrush } from "react-icons/fa";
import { FaCode } from "react-icons/fa";
import { AiTwotoneBug } from "react-icons/ai";
import { IoIosRocket } from "react-icons/io";
import { MdSupportAgent } from "react-icons/md";

function Introduction() {
  return (
    <div className="introduction" id="downElement">
      <IntroductionBox
        introductionLabel="Čime se bavimo u Mathscriptu?"
        introductionExplanation="Kao mlada i iskusna web-agencija, pomažemo našim partnerima diljem svijeta kako bi pronašli svoje mjesto u digitalnom svijetu. Evo kako i tebi možemo pomoći."
        introductionLink="/usluge"
      >
        <div className="long-line"></div>
        <div className="introduction-list-box">
          {whatWeDo.map((what, index) => {
            return (
              <div key={index} className="introduction-list-images">
                <img
                  src={require(`../files/${what.whatSrc}.jpeg`)}
                  alt={what.whatAlt}
                  draggable="false"
                />
                <p>{what.whatText}</p>
              </div>
            );
          })}
        </div>
      </IntroductionBox>

      <IntroductionBox
        introductionLabel="Koje tehnologije koristimo?"
        introductionExplanation="Težimo tome da smo drukčiji od ostalih. Stoga koristimo najnovije tehnologije i pratimo promjene u trendovima redovito. Ne koristimo spore i vidno loše predloške kao mnogi, već kod pišemo posebno svakome. Evo na koji način."
        introductionLink="/o-nama"
      >
        <div className="long-line"></div>

        <div className="introduction-list-box">
          {frameworks.map((fw, index) => {
            return (
              <div key={index} className="introduction-list-images">
                <img
                  src={require(`../files/${fw.fwSrc}`)}
                  alt={fw.fwAlt}
                  draggable="false"
                  className="no-cover radius"
                />
                <p>{fw.fwText}</p>
              </div>
            );
          })}
        </div>
      </IntroductionBox>

      <IntroductionBox
        introductionLabel="Kako možemo dogovoriti suradnju?"
        introductionExplanation="Ako si odlučio iskoračiti korak naprijed, bili bismo sretni da odabereš baš nas. Svakog klijenta tretiramo s istim poštovanjem. Za razliku od drugih, mi se pokušavamo prilagoditi tvojim željama i mogućnostima."
        introductionLink="/radovi"
      >
        <div className="long-line"></div>

        <div className="introduction-list-box">
          <div className="inner-slider2">
            <BsPatchQuestionFill className="icon-setter" />
            <p>1. Čujemo se porukom</p>
          </div>
          <div className="inner-slider2">
            <FaReceipt className="icon-setter" />
            <p>2. Formalno prihvaćamo suradnju</p>
          </div>
          <div className="inner-slider2">
            <BsFillPenFill className="icon-setter" />
            <p>3. Izrađujemo skicu izrade projekta</p>
          </div>
          <div className="inner-slider2">
            <MdAppRegistration className="icon-setter" />
            <p>
              4. Pripremamo okvir izrade i upoznajemo klijenta s okvirnim
              vremenom trajanja
            </p>
          </div>
          <div className="inner-slider2">
            <FaPaintBrush className="icon-setter" />
            <p>5. Počinjemo s dizajnom</p>
          </div>
          <div className="inner-slider2">
            <FaCode className="icon-setter" />
            <p>6. Nastavljamo s programiranjem i kodiranjem</p>
          </div>
          <div className="inner-slider2">
            <AiTwotoneBug className="icon-setter" />
            <p>
              7. Testiramo tvoj projekt kako bi u konačnoj verziji bio savršen
              za tvoje klijente
            </p>
          </div>
          <div className="inner-slider2">
            <IoIosRocket className="icon-setter" />
            <p>
              8. Rješavamo i domenu i hosting, da ti ne trebaš ni misliti o tome
            </p>
          </div>
          <div className="inner-slider2">
            <MdSupportAgent className="icon-setter" />
            <p>9. Nakon izrade, i dalje imaš našu punu podršku</p>
          </div>
        </div>
      </IntroductionBox>
    </div>
  );
}

export default Introduction;
