import React, { useState } from "react";
import "./SpreadMenu.css";
import { Link } from "react-router-dom";

import mathscript from "../files/favicon.png";

function SpreadMenu() {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <div className="spreadmenu">
        {isShown ? "" : <h3>Istraži Mathscript &#x02008; &#x02ABC;</h3>}
        <img
          src={mathscript}
          alt="Logo Mathscripta u Zagrebu, Hrvatska, Europska unija"
          draggable="false"
          onClick={() => setIsShown(!isShown)}
        />
      </div>
      {/*  <div
        className={
          isShown
            ? "spreaded-content-layer-between show"
            : "spreaded-content-layer-between"
        }
      >
        <div className="spreaded-content" onClick={() => setIsShown(false)}>
          <img
            src={mathscript}
            alt="Logo Mathscripta u Zagrebu, Hrvatska, Europska unija"
            draggable="false"
            onClick={() => setIsShown(false)}
          />
          <Link to="/">Početna</Link>
          <Link to="/usluge">Usluge</Link>
          <Link to="/o-nama">O nama</Link>
          <Link to="/radovi">Radovi</Link>
          <Link to="/karijere">Karijere</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/kontakt">Kontakt</Link>
          <a href="https://mathscript.eu" className="english">
            {" "}
          </a>
        </div>
      </div> */}

      <div className={isShown ? "spread-cont blow" : "spread-cont"}>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
        <div className={isShown ? "box blow" : "box"}></div>
      </div>
      <div className={isShown ? "real-cont blow" : "real-cont"}>
        <img
          src={mathscript}
          alt="Logo Mathscripta u Zagrebu, Hrvatska, Europska unija"
          draggable="false"
        />
        <h1>MATHSCRIPT</h1>
        <h3>Agencija za računalno programiranje</h3>
        <div className="org-link">
          <Link to="/">Početna</Link>
          <Link to="/usluge">Usluge</Link>
          <Link to="/o-nama">O nama</Link>
          <Link to="/radovi">Radovi</Link>
          <Link to="/karijere">Karijere</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/kontakt" className="special">
            Kontakt
          </Link>
          <a href="https://mathscript.eu" className="english">
            {" "}
          </a>
        </div>
      </div>
    </>
  );
}

export default SpreadMenu;
