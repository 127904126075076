import React from "react";
import "./NewStandards.css";

function NewStandards() {
  return (
    <div className="new-standards">
      <h3>Iskustvo i posvećenost poslu </h3>
      <div className="ns-inner">
        <div className="ns-inner-text">
          <h1>20+</h1>
          <h3>PROJEKATA</h3>
          <span></span>
          <p>Samo kao Mathscript, bez uključenog prijašnjeg iskustva</p>
        </div>
        <div className="ns-inner-text">
          <h1>5+</h1>
          <h3>GODINA ISKUSTVA</h3>
          <span></span>
          <p>
            Višegodišnje radno iskustvo i edukacija u programiranju, prije
            pokretanja Mathscripta
          </p>
        </div>
        <div className="ns-inner-text">
          <h1>4.8</h1>
          <h3>PROSJEČNA OCJENA KORISNIKA</h3>
          <span></span>
          <p>
            Klijenti su naš rad ocijenili gotovo idealnom ocjenom pa bismo to
            željeli čuti i od tebe.
          </p>
        </div>
        <div className="ns-inner-text">
          <h1>120+</h1>
          <h3>SATI GODIŠNJE EDUKACIJE</h3>
          <span></span>
          <p>
            Broj sati koje odvajamo po zaposleniku godišnje s ciljem poboljšanja
            znanja.
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewStandards;
