import React, { useEffect } from "react";
import "./CanvasDraw.css";
import { ImArrowDown } from "react-icons/im";

import { FaAppStoreIos } from "react-icons/fa";
import { GiWorld } from "react-icons/gi";
import { GrReactjs } from "react-icons/gr";
import { MdWebStories } from "react-icons/md";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { GiCubes } from "react-icons/gi";

function CanvasDraw(props) {
  let downElement;
  useEffect(() => {
    downElement = document.getElementById("downElement");
  }, []);

  return (
    <div className="canvas-draw">
      <div className="canvas-div">
        {/*  <div className="videoover">
          <video
            className="videopl"
            src={require("../files/videoearth.mp4")}
            autoPlay
            muted
            loop
          ></video>
          <div className="videodiv"></div>
        </div> */}
        <h1>{props.header}</h1>
        {/* <h3>{props.middleHeader}</h3> */}
        <p>{props.messageHeader}</p>

        <div className="circles">
          <div className="circle">
            <FaAppStoreIos className="circle-icon" />
            <p>IZRADA APLIKACIJA</p>
          </div>

          <div className="circle">
            <GrReactjs className="circle-icon" />
            <p>CUSTOM SOFTVERI</p>
          </div>

          <div className="circle">
            <MdWebStories className="circle-icon" />
            <p>IZRADA WEB-STRANICA</p>
          </div>

          <div className="circle">
            <BsFillCreditCard2BackFill className="circle-icon" />
            <p>IZRADA WEBSHOPA</p>
          </div>

          <div className="circle">
            <GiCubes className="circle-icon" />
            <p>DIZAJN I MARKETING</p>
          </div>

          <div className="circle">
            <GiWorld className="circle-icon" />
            <p>DIGITALI-ZACIJA TVRTKI</p>
          </div>
        </div>
      </div>
      {/*       {props.toShow && <div className="world"></div>}
       */}
      {props.toShow && (
        <div className="card-overlay">
          <h3>Što nas izdvaja od ostalih?</h3>
          <p>
            Počeli smo se baviti kodiranjem, programiranjem i IT-djelatnostima
            prije puno godina. Danas smo zreli tim koji može korisnicima
            izraditi doslovno sve što oni požele i zamisle.
          </p>

          <div className="cards">
            <div className="card">
              <div className="front">
                <h3> SAMO "CUSTOM" PROIZVODI</h3>
                <p>
                  Nismo jedni od onih koji koriste kopije i predloške koji su
                  jeftini, spori i dovode tvoj posao na neozbiljan glas. Kod
                  pišemo stručno, od nule — svakome.
                </p>
              </div>
              <div className="back">
                <p>
                  Tvoj budući proizvod izradit ćemo profesionalno i to od izrade
                  plana, preko dizajna pa sve do pisanja programskog koda i
                  osnovne SEO-optimizacije da bi tvoja stranica bila primjetna
                  Googleu. Tvoj proizvod je stoga unikatan i ističe se od
                  konkurencije.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>MERN I SPA-TEHNOLOGIJE ČINE TVOJ PROIZVOD BRŽIM</h3>
                <p>
                  Tehnologije koje koristimo učinit će tvoj proizvod nešto
                  drukčijim od većine drugih. Bit će brži i zbog posebnog
                  "routinga" korisnici će imati dojam glatkog korištenja, kao da
                  umjesto weba koriste aplikaciju na mobitelu.
                </p>
              </div>
              <div className="back">
                <p>
                  Imaj na umu da velika većina korisnika danas pretražuje
                  internet putem mobitela. Stoga bi tvoji potencijalni klijenti,
                  zbog dojma kao da koriste mobilnu aplikaciju umjesto weba,
                  stvorili pozitivniji dojam o tebi, a u konačnici se to
                  odražava i na financije tvoje tvrtke.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>PROFESIONALNOST I OZBILJNOST</h3>
                <p>
                  Veliki broj klijenata ima velikih poteškoća jer unajme loše
                  "programere" koji im stranicu odrade nepotpuno, a kasnije se
                  ne javljaju i zbog toga trpe gubitke. Vaša tvrtka je dio vašeg
                  života i povjerite ju samo onima koji će posao odraditi ne
                  dobro, već savršeno.
                </p>
              </div>
              <div className="back">
                <p>
                  Mathscript je profesionalna agencija koju čine osobe s
                  višegodišnjom edukacijom u izradi softvera. Tvoj proizvod
                  izradit ćemo s pažnjom, uredno i čak od tebe tražiti završnu
                  potvrdu, kako bismo i dodatno rješenje prilagodili tebi.
                  Uvjerit ćeš se i sam nakon što nam pošalješ poruku.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>DOSTUPNOST SVAKI DAN ZA TVOJE UPITE</h3>
                <p>
                  Shvaćamo da su digitalne usluge bitne, možda i presudne za
                  tvoj posao. Stoga, ako ćeš trebati nadogradnju, izmjenu,
                  dopunu ili prijaviti nešto treće, bit ćemo ti na raspolaganju
                  svaki dan. Niti jedan dan nećeš biti "ostavljen sam".
                </p>
              </div>
              <div className="back">
                <p>
                  Jedna od velikih zamjerki IT-kompanijama je radno vrijeme i
                  nedostupnost u mnoge dane, osobito vikendom, što većina
                  korisnika ocjenjuje neprofesionalnim. Zato smo za tebe i u
                  dane kada većina drugih nije i to zato jer svoj posao volimo i
                  ne predstavlja nam teret.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>NE BOJ SE DIGITALNIH USLUGA ZBOG CIJENA</h3>
                <p>
                  Digitalne i IT-usluge, kako proizlazi iz jedne TV-šale, na
                  glasu su kao usluge s astronomskim cijenama. To nije tako,
                  naravno. Mathscript ponude prilagođava i velikima i malima i
                  ne nudi usluge samo jednima ili drugima. Najbolje pošalji
                  poruku pa se i sam uvjeri.
                </p>
              </div>
              <div className="back">
                <p>
                  Ne postoji ni jedan jedini razlog da svoj posao u 21. st.
                  držiš nedigitaliziranim. Nalazimo se u digitalnom dobu i
                  Mathscript je u njemu prepoznao potrebe svih sudionika
                  tržišta. Neka te strah od cijene ne koči, javi nam se i naći
                  ćemo zajedničko rješenje kojim ćemo modernizirati izgled tvog
                  poslovanja pred tvojim kupcima.
                </p>
              </div>
            </div>

            <div className="card">
              <div className="front">
                <h3>NOVAC ZA KLIJENTA, POPUST UDRUGAMA, KRIPTOVALUTE...</h3>
                <p>
                  Mathscript ima cijeli niz pogodnosti i posebnih akcija. Jedna
                  od njih je ta da ćemo ti na račun uplatiti novce ako nam samo
                  preporučiš klijenta. Neprofitnim organizacijama također bez
                  iznimke odobravamo popust kao oblik podrške civilnom društvu.
                  Izdvojimo i da nam možeš platiti u kriptovalutama!
                </p>
              </div>
              <div className="back">
                <p>
                  Sve što si pročitao su tek neke od pogodnosti. Primjerice, u
                  blagdansko vrijeme možemo imati i posebne nagrade za naše
                  trenutne klijente, uključivati klijente u nagradne igre na
                  europskoj razini u kojima mogu za najbolju web-stranicu dobiti
                  novčane poticaje i slično. U svakom slučaju — vjerujemo da ćeš
                  biti zadovoljan.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.toShow && (
        <div
          className="motto-canvas"
          beforeName="Upoznaj nas malo bolje"
          onClick={() => downElement.scrollIntoView(true)}
        >
          Pomakni svoje poslovanje korak dalje{" "}
          <ImArrowDown className="motto-icon" />
        </div>
      )}
    </div>
  );
}

export default CanvasDraw;

/* 
Upoznajmo se bolje za početak


Pomaknite svoje poslovanje korak dalje

 */
