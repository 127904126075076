import React from "react";
import "./CitiesAffiliate.css";

function CitiesAffiliate() {
  return (
    <div className="cities-affiliate">
      <h1>Sjedište u Zagrebu, uskoro i u Londonu i Stockholmu</h1>
      <div className="ca-container">
        <div className="ca-container-img" data-city="ZAGREB"></div>
        <div
          className="ca-container-img"
          data-city="LONDON (do kraja ove godine)"
        ></div>
        <div
          className="ca-container-img"
          data-city="STOCKHOLM (do kraja ove godine)"
        ></div>
      </div>
    </div>
  );
}

export default CitiesAffiliate;
