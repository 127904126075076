import React, { useEffect } from "react";
import "./About.css";

import Navigation from "../components/Navigation";
import ContactButton from "../components/ContactButton";
import CanvasDraw from "../components/CanvasDraw";
import GotProject from "../components/GotProject";
import Footer from "../components/Footer";
import SpreadMenu from "../components/SpreadMenu";
import AboutDetails from "../components/AboutDetails";

function About() {
  document.title = "O nama — Mathscript";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="about">
      <Navigation />
      <ContactButton />
      <SpreadMenu />
      <CanvasDraw
        header="Upoznaj nas bolje"
        middleHeader=""
        messageHeader="Želiš li znati više o nama? Nema problema, ovdje su informacije koje bi možda htio znati. Zanima li te bilo što dodatno, slobodno pitaj u bilo kojem trenutku. Idemo redom prije nego prijeđemo na tvoj projekt."
      />

      <AboutDetails />

      <GotProject />
      <Footer />
    </div>
  );
}

export default About;
