import React from "react";
import { Link } from "react-router-dom";

import "./Navigation.css";

function Navigation() {
  return (
    <>
      <div className="navigation">
        <Link data-link="Usluge" to="/usluge">
          Usluge
        </Link>
        <Link data-link="O nama" to="/o-nama">
          O nama
        </Link>
        <Link data-link="Radovi" to="/radovi">
          Radovi
        </Link>
        <Link data-link="Karijere" to="/karijere">
          Karijere
        </Link>
        <Link data-link="Blog" to="/blog">
          Blog
        </Link>
        <Link data-link="Kontakt" to="/kontakt">
          Kontakt
        </Link>
      </div>

      <div className="small-navigation">
        <p data-link="Mathscript.">Znanje.</p>
        <p data-link="Mathscript.">Iskustvo.</p>
        <p data-link="Mathscript.">Vizija.</p> 
      </div>
    </>
  );
}

export default Navigation;
