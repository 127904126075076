export const whatWeDo = [
  {
    whatText: "WEB-STRANICE",
    whatSrc: "website-a",
    whatAlt:
      "Website design u Hrvatskoj - Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea ",
  },

  {
    whatText: "WEB-APLIKACIJE",
    whatSrc: "webapp-a",
    whatAlt:
      "Dizajn web-aplikacija - Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea ",
  },

  {
    whatText: "WEBSHOPOVI",
    whatSrc: "webshop-a",
    whatAlt:
      "Webshop u Hrvatskoj, prihvatljiva i povoljna cijena - Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea ",
  },

  {
    whatText: "MOBILNE APLIKACIJE",
    whatSrc: "mobile-app",
    whatAlt:
      "Mobilne aplikacije i aplikacije za pametne uređaje - Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea ",
  },

  {
    whatText: "DIZAJN (UX/UI)",
    whatSrc: "design-a",
    whatAlt: "Mathscript - dizajn loga, slika, skica, videa. UX / UI design.",
  },

  {
    whatText: "IZRADA SADRŽAJA UMJESTO KORISNIKA",
    whatSrc: "content-create-a",
    whatAlt:
      "Kreiranje sadržaja i priprema istog za web umjesto korisnika - Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea ",
  },

  {
    whatText: "CMS",
    whatSrc: "cms-a",
    whatAlt:
      "Razvoj CRM-a za samouređivanje sadržaja stranica - Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea ",
  },

  {
    whatText: "SEO — BOLJA POZICIJA NA GOOGLEU",
    whatSrc: "seo-a",
    whatAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea nudi SEO-optimizaciju za bolju poziciju stranice na Googleovom pretraživaču",
  },

  {
    whatText: "DOMENA I HOSTING",
    whatSrc: "hosting-a",
    whatAlt:
      "Zakup domene i ponuda hostinga - Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea ",
  },

  {
    whatText: "MARKETING I PAMETNO OGLAŠAVANJE",
    whatSrc: "social-media-a",
    whatAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea - pametno ili smart oglašavanje na internetu, Googleu i društvenim mrežama, prije svega na Facebooku",
  },

  {
    whatText: "ODRŽAVANJE POSLOVNIH PROFILA NA DRUŠTVENIM MREŽAMA",
    whatSrc: "sm-a",
    whatAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - povoljna cijena održavanja i vođenja profila na društvenim mrežama za poslovne korisnike i promociju web-stranica",
  },

  {
    whatText: "GRAMMAR-CHECK NA HRVATSKOM I ENGLESKOM",
    whatSrc: "lecturing-a",
    whatAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  -  provjera pravopisa i gramatike za tvoj sadržaj na web-stranici ili prije puštanja iste u pogon i rad",
  },
];

export const frameworks = [
  {
    fwText: "REACT JS (frontend)",
    fwSrc: "reactjs.png",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - React JS, frontend",
  },

  {
    fwText: "NODE JS (runtime-environment za Javascript)",
    fwSrc: "nodejs.jpeg",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - web u Node JS-u",
  },
  {
    fwText: "EXPRESS JS (backend)",
    fwSrc: "expressjs.jpeg",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - web design u Express JS-u za server - backend",
  },
  {
    fwText: "MONGO DB (baze podataka)",
    fwSrc: "mongodb.png",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - Mongo DB pruža uslugu Mongo Atlas Cloud gdje se mogu skladištiti podatci na internetu",
  },
  {
    fwText: "JAVASCRIPT — JS (programski jezik)",
    fwSrc: "js.png",
    fwAlt:
      "Javascript, jezik web-pretraživača, kao što je Google Chrome - V8 Engine, najpopularniji i najkorišteniji jezik",
  },

  {
    fwText: "REACT NATIVE — MOBILNE APLIKACIJE",
    fwSrc: "react-native.png",
    fwAlt:
      "React Native izrađuje mobilne aplikacije, i Android i iOS. Nema potrebe koristiti SWIFT, dCode, programski jezik Java, Android Studio i slične alate",
  },

  {
    fwText: "SCSS — SASS (stiliziranje)",
    fwSrc: "sass.jpeg",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea koristi SCSS - SASS kao napredniju verziju CSS-a za još oku ugodniji dizajn i savršen izgled",
  },

  {
    fwText: "HTML i CSS (stiliziranje)",
    fwSrc: "html-css.png",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - HTML je uglavnom zamijenjen JSX-om u Reactu, dok CSS koristimo usporedno s SCSS-om",
  },
  {
    fwText: "FIGMA",
    fwSrc: "figma.png",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - Figma, kao što su Illustrator ili donekle Photoshop. Grafički dizajn weba.",
  },
  {
    fwText: "FACEBOOK ADS, GOOGLE ADS",
    fwSrc: "fb-ads.png",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - in addition to creating websites, we also offer advertising through the Facebook platform. Advanced and smart advertising. Favorable, quality, reliable results, optimal price-quality ratio. Possibility of advertising through Google - Google Ads. Favorable, quality, reliable results, optimal price-quality ratio.",
  },
  {
    fwText: "TENSORFLOW JS (umjetna inteligencija, AI)",
    fwSrc: "tf.png",
    fwAlt:
      "Mathscript agencija za web i izradu web-stranica, aplikacija i softwarea  - Tensorflow JS, alat koji povezuje umjetnu inteligenciju s programskim jezikom Javascript, umjesto standarda poput Pythona za AI (artificial intelligence)",
  },
];
