import React from "react";
import "./LastProjects.css";
import ScrollContainer from "react-indiana-drag-scroll";

import projectDJB from "../files/project-dostajebilo.png";
import projectMaja from "../files/project-fm.png";
import projectmmp7 from "../files/project-mmp7.png";
import projectLucich from "../files/project-luchich.png";
import projectEmmHouse from "../files/project-emmhouse.png";
import projectNikolich from "../files/project-nikolich.png";
import projectSolin from "../files/project-solin.png";
import projectRenata from "../files/projectrenata.png";

function LastProjects() {
  return (
    <div className="last-projects">
      <h1>Neki od odabranih klijenata samo u 2022.</h1>
      <p>
        Kao Mathscript, dosad smo surađivali već s nekoliko kompanija i
        organizacija civilnog društva. Naši zaposlenici također posjeduju i
        višegodišnje iskustvo iz prijašnje faze svog života. Izdvajamo zadnje
        projekte od početka proljeća 2022.
      </p>
      <ScrollContainer className="project-logos">
        <img
          src={projectMaja}
          alt="Primjer projekta kojeg je izradio Mathscript u Reactu i Node JS-u"
          draggable="false"
        />
        <img
          src={projectEmmHouse}
          alt="Primjer projekta kojeg je izradio Mathscript u Reactu i Node JS-u"
          draggable="false"
        />
        <img
          src={projectLucich}
          alt="Primjer projekta kojeg je izradio Mathscript u Reactu i Node JS-u"
          draggable="false"
        />
        <img
          src={projectSolin}
          alt="Primjer projekta kojeg je izradio Mathscript u Reactu i Node JS-u"
          draggable="false"
        />
        <img
          src={projectDJB}
          alt="Primjer projekta kojeg je izradio Mathscript u Reactu i Node JS-u"
          draggable="false"
          id="first"
        />

        <img
          src={projectNikolich}
          alt="Primjer projekta kojeg je izradio Mathscript u Reactu i Node JS-u"
          draggable="false"
        />
        <img
          src={projectmmp7}
          alt="Primjer projekta kojeg je izradio Mathscript u Reactu i Node JS-u"
          draggable="false"
        />
        <img
          src={projectRenata}
          alt="Primjer projekta kojeg je izradio Mathscript u Reactu i Node JS-u"
          draggable="false"
        />
      </ScrollContainer>
    </div>
  );
}

export default LastProjects;
